import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const PreloadImage = ({ srcWebP, srcFallback, alt, ...props }) => {
  const [loadedSrc, setLoadedSrc] = useState(null);

  useEffect(() => {
    // Pré-chargement de l'image WebP
    const imgWebP = new Image();
    imgWebP.onload = () => setLoadedSrc(srcWebP);
    imgWebP.src = srcWebP;

    // Pré-chargement de l'image de repli (fallback)
    const imgFallback = new Image();
    imgFallback.src = srcFallback;
  }, [srcWebP, srcFallback]);

  if (srcWebP !== loadedSrc) {
    return null; // ou un placeholder pendant le chargement
  }

  return (
    <picture>
      <source srcSet={srcWebP} type="image/webp" />
      <img src={srcFallback} alt={alt} {...props} />
    </picture>
  );
};

PreloadImage.propTypes = {
  srcWebP: PropTypes.string,
  srcFallback: PropTypes.string,
  alt: PropTypes.string,
};

export default PreloadImage;
