import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { shouldFetchData, status } from '../../static/data/fetchStatus.js';
import Notation from '../Shared/Notation.js';
import QuotesModal from './QuotesModal.js';
import Services from './quote.service.js';
import useToast from '../Toasts/useToast.js';
import {
  ToastHeaders,
  ToastThemes,
  ToastMessages,
} from '../Toasts/ToastOptions/toastOptions.js';

function QuotesCards(props) {
  const enqueueToast = useToast();

  const [showModal, setShowModal] = useState(false);

  const [expert, setExpert] = useState({});
  const [expertFetched, setExpertFetched] = useState(status.DEFAULT);

  const fetchExpert = useCallback(async () => {
    setExpertFetched(status.FETCHED);

    try {
      const expertData = await Services.getExpertInfoById(props.data.expertId);

      /* expertData = expertData.length > 0 ? expertData[0] : expertData;

      let cabinetData = await Services.cabinetByID(expertData?.cabinetId);

      cabinetData = cabinetData.length > 0 ? cabinetData[0] : cabinetData;

      const locationData = await Services.locationByID(expertData?.locationId);

      const cabinet = {
        cabinet: {
          ...cabinetData,
          location: locationData,
        },
      };

      expertData = { ...expertData, ...cabinet, location: locationData };

      const skillsData = await Services.skillsServices.expertHasSkills(
        expertData?.id
      );

      expertData.skills = skillsData?.skills || [];

      const ratingData = await Services.ratingService.ratingByID(
        expertData?.ratingId
      );

      expertData.rating = ratingData;

      const userData = await Services.userService.userByID(expertData?.userId);

      expertData.user = userData; */

      setExpert(expertData);
    } catch (error) {
      enqueueToast(ToastMessages.ERROR_OCCURED_FETCH, {
        theme: ToastThemes.toastThemes.ERROR_OCCURED,
        header: ToastHeaders[ToastThemes.toastThemes.ERROR_OCCURED],
        duration: 3000,
      });
    }
  }, [props.data.expertId, enqueueToast]);

  useEffect(() => {
    if (shouldFetchData(expertFetched)) fetchExpert();
  }, [props.data.id, expertFetched, fetchExpert]);

  // Fonction qui permet l'affichage de la popup de confirmation de selection de l'expert

  function selectExpert() {
    setShowModal(true);
  }

  // Fonction qui permet de fermer la modal en cliquant sur le container-modal

  function closeModal(event) {
    event.stopPropagation();
    setShowModal(false);
  }

  function getSkills() {
    const skillsArray = [];

    expert?.skills?.map(
      (skill) =>
        skill?.category === 'Specialites' && skillsArray.push(skill?.name)
    );

    return skillsArray.join(', ');
  }

  function getSoftware() {
    const softwareArray = [];

    expert?.skills?.map(
      (skill) =>
        skill?.category === 'Logiciels' && softwareArray.push(skill?.name)
    );

    return softwareArray.join(', ');
  }

  return (
    <div className="quote-card" id={props.id}>
      <div className="upper-card">
        <div className="expert-details">
          <div className="expert-name-location">
            <div className="expert-name">
              {`Expert-comptable 0${props.number + 1}`}
            </div>
            <div className="expert-location">
              {expert?.location?.city || 'N/A'}
            </div>
          </div>
        </div>
        <div className="expert-price section">
          <div className="card-titles">Prix estimé</div>
          <div className="price">
            <span>
              {!props.data.price || parseInt(props?.data?.price, 10) === 0
                ? 'À déterminer lors du rendez-vous'
                : `${(props.data.price / 12).toFixed(2)}€* HT/mois`}
            </span>
          </div>
        </div>
        <div className="expert-description section">
          <div className="card-titles">Description</div>
          <div className="card-details">{expert?.biography}</div>
        </div>
        {getSkills().length !== 0 && (
          <div className="expert-speciality section">
            <div className="card-titles">Spécialité</div>
            <div className="card-details">{getSkills() || 'N/A'}</div>
          </div>
        )}
        {getSoftware().length !== 0 && (
          <div className="expert-software section">
            <div className="card-titles">Logiciel utilisé</div>
            <div className="card-details">{getSoftware() || 'N/A'}</div>
          </div>
        )}
        <div className="expert-notation section">
          <div className="card-titles">Note</div>
          <Notation rating={expert?.rating?.average || 1} disabled={true} />
        </div>
      </div>
      {!props.disabled && (
        <button className="choose-card" onClick={() => selectExpert()}>
          Sélectionner cet expert
        </button>
      )}
      {showModal && (
        <QuotesModal
          closeModal={(e) => closeModal(e)}
          setShowModal={(bool) => setShowModal(bool)}
          requestAccountCreation={props?.requestAccountCreation}
          expert={expert}
        />
      )}
    </div>
  );
}

QuotesCards.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  requestAccountCreation: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  number: PropTypes.any.isRequired,
  id: PropTypes.any.isRequired,
};

export default QuotesCards;
