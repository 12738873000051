import React from 'react';
import '../../css/solution.css';
import settings from '../../css/images/settings.svg';
import money from '../../css/images/money.svg';
import info from '../../css/images/info.svg';

const Explain = () => (
  <div className="solution-container">
    <div className="solution-title">
      <div id="nos-solutionsAn"></div>
      <h2 id="nos-solutions">
        <span className="title-bluesoft">
          Simple et rapide :
          <br />
        </span>{' '}
        Trouvez un expert-comptable et ses tarifs (3 à 5 minutes)
      </h2>
      <p>
        Trouvez l'expert-comptable idéal en quelques clics sur notre
        comparateur. Comparez les tarifs et les services de plusieurs
        experts-comptables et recevez trois devis gratuits.
      </p>
    </div>
    <div className="solution-grid reswrap">
      <div className="solution-card tred">
        <div className="icons">
          <img className="solution-icons" src={settings} alt="" />
        </div>
        <div className="solution-subtitle-r">
          <h3>1. Remplissez le formulaire</h3>
          <p>Précisez vos besoins pour trouver un expert-comptable idéal</p>
        </div>
      </div>
      <div className="solution-card tblue">
        <div className="icons">
          <img className="solution-icons" src={money} alt="" />
        </div>
        <div className="solution-subtitle-g">
          <h3>2. Recevez 3 devis gratuits sur mesure</h3>
          <p>
            Comparez 3 devis
            <br /> d'experts-comptables et choisissez le meilleur selon vos
            besoins et votre budget.
          </p>
        </div>
      </div>
      <div className="solution-card tyellow">
        <div className="icons">
          <img className="solution-icons" src={info} alt="" />
        </div>
        <div className="solution-subtitle-y">
          <h3>3. Sélectionnez et planifiez</h3>
          <p>
            Choisissez le devis qui vous convient sur votre espace personnel et
            fixez des rendez-vous.
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default Explain;
