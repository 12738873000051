import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { config } from '../../static/data/site.config.js';

const SEO = ({ pageType, pageURL, pageTitle, pageDescription, pageImage }) => {
  const title = pageTitle || config.siteTitle;
  const description = pageDescription || config.siteDescription;
  const image = pageImage || config.siteLogo;
  const url = pageURL || config.siteUrl;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="image" content={image} />
      <link rel="canonical" href={pageURL} />

      <meta property="og:url" content={url} />
      <meta property="og:type" content={pageType} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  );
};

SEO.propTypes = {
  pageURL: PropTypes.string,
  pageType: PropTypes.string,
  pageTitle: PropTypes.string,
  pageDescription: PropTypes.string,
  pageImage: PropTypes.string,
};

export default SEO;
