import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import '../../css/header.css';
import logo from '../../css/images/logo.svg';
import { HashLink } from 'react-router-hash-link';

const FRONT_CLIENT_URL =
  process.env.REACT_APP_FRONT_CLIENT_URL ||
  'https://espace.choisir-son-expert-comptable.fr/login';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = false;
    this.toggleClick = this.toggleClick.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      const width = window.innerWidth;

      if (width > 1200) {
        const navbarMobile = document.getElementById('navbar-mobile');
        navbarMobile.classList.remove('show');
        navbarMobile.style.height = 'auto';
        this.toggle = false;
      }
    });
  }

  static scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  static propTypes = {
    pageName: PropTypes.string,
  };

  toggleClick() {
    this.toggle = !this.toggle;

    if (this.toggle === false) {
      const navbarMobile = document.getElementById('navbar-mobile');

      navbarMobile.classList.remove('show');
      navbarMobile.classList.remove('collapse');
      navbarMobile.style.height = 0;
      navbarMobile.classList.add('collapsing');

      setTimeout(() => {
        navbarMobile.classList.remove('collapsing');
        navbarMobile.classList.add('collapse');
      }, 500);
    }

    if (this.toggle === true) {
      const navbarMobile = document.getElementById('navbar-mobile');

      navbarMobile.classList.remove('collapse');
      const childrenHeight = `${navbarMobile.children[1].clientHeight}px`;
      navbarMobile.style.height = childrenHeight;
      navbarMobile.classList.add('collapsing');
      setTimeout(() => {
        navbarMobile.classList.remove('collapsing');
        navbarMobile.classList.add('collapse');
        navbarMobile.classList.add('show');
      }, 500);
    }
  }

  render() {
    const headerClass = `header ${this.props.pageName}`;

    return (
      <div className={headerClass}>
        <nav className="navbar navbar-expand-xl navbar-dark navbar-padding">
          <Link
            aria-label="Page d'accueil"
            to="/"
            onClick={Header.scrollToTop()}
          >
            <img className="navbar-collapse-logo" src={logo} alt="logo CEC" />
          </Link>
          <button
            className="navbar-toggler"
            aria-label="Menu"
            type="button"
            onClick={this.toggleClick}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse d-flex justify-content-center navbar-fullwidth"
            id="navbar-mobile"
          >
            <Link to="/">
              <img className="logo" src={logo} alt="logo CEC" />
            </Link>
            <ul className="navbar-nav d-flex justify-content-around ul-full-width align-items-center">
              <li className="nav-item active">
                <HashLink to="/" className="nav-link text-white poppins-r16">
                  Accueil
                </HashLink>
              </li>
              <li className="nav-item">
                <HashLink
                  to="/qui-sommes-nous"
                  className="nav-link text-white poppins-r16"
                >
                  Qui sommes nous?
                </HashLink>
              </li>
              <li className="nav-item">
                <HashLink
                  to="/contact-expert"
                  className="nav-link text-white poppins-r16"
                >
                  Vous êtes expert-comptable?
                </HashLink>
              </li>
              <li className="nav-item">
                <HashLink
                  to="/articles"
                  className="nav-link text-white poppins-r16"
                >
                  Articles
                </HashLink>
              </li>
              <li className="nav-item">
                <HashLink
                  to="/contact"
                  className="nav-link text-white poppins-r16"
                >
                  Contact
                </HashLink>
              </li>

              <Link to="/devis" className="devis-btn">
                Devis gratuit
              </Link>
              <Link to={FRONT_CLIENT_URL} className="client-btn">
                Espace client
              </Link>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

export default Header;
