import React, { forwardRef } from 'react';
import trophy from '../../../css/images/green_trophy.svg';
import Quotes from '../../Quotes/Quotes.js';

const Step8 = forwardRef(() => (
  <>
    <div className="devis-btn-wrapper">
      <img src={trophy} alt="trophée"></img>
    </div>
    <p className="devis-success">
      <b>Merci d&apos;avoir soumis votre demande de devis. </b>
      <br />
      Sélectionnez le devis qui vous convient pour être mis en contact avec
      l&apos;expert correspondant.
    </p>
    <Quotes />
  </>
));

Step8.displayName = 'Step8';

export default Step8;
