import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import greenStar from '../../css/images/green-star.svg';
import greyStar from '../../css/images/grey-star.svg';

function Notation(props) {
  const [starsCount, setStarsCount] = useState(props.rating);

  useEffect(() => {
    setStarsCount(props.rating);
  }, [props.rating]);

  function displayStars() {
    const arrayStars = [];

    for (let i = 1; i <= 5; i++) {
      if (i <= starsCount) {
        arrayStars.push(
          <img
            src={greenStar}
            alt="green star"
            className="green-star"
            role="button"
            key={i}
          />
        );
      } else {
        arrayStars.push(
          <img
            src={greyStar}
            alt="grey star"
            className="grey-star"
            role="button"
            key={i}
          />
        );
      }
    }

    return arrayStars;
  }

  return <div className="card-stars">{displayStars()}</div>;
}

Notation.propTypes = {
  rating: PropTypes.any.isRequired,
};

export default Notation;
