import React from 'react';
import { Link } from 'react-router-dom';
import fb from '../../css/images/fb.svg';
import linkedin from '../../css/images/linke.svg';
import insta from '../../css/images/insta.svg';
import logo from '../../css/images/logoresize.png';
import '../../css/footerextented.css';

const Footerextented = () => (
  <div className="footerext">
    <div className="footer-1 double-width">
      <div className="logo-s">
        <img className="solution-logo-s" src={logo} alt="" />
      </div>
      <div className="text-blue marge">c'est quoi CEC?</div>
      <div className="marge textlong">
        CEC c'est la plateforme en ligne qui révolutionne votre recherche du
        comptable parfait pour votre entreprise ou commerce le tout en ligne !
      </div>
    </div>

    <div className="footer-2">
      <div className="text-blue marge">Horaires</div>
      <div className="marge">
        Du lundi au vendredi
        <br /> De 8h à 18h30
      </div>
      <div className="text-blue marge">Contactez-nous</div>
      <div className="marge">Tél: +33 7 66 20 77 52</div>
      <div className="marge">
        <a className="footer-link" href="mailto:contact@choisir-ec.fr">
          contact@choisir-ec.fr
        </a>
      </div>
    </div>

    <div className="footer-3">
      <div className="text-blue marge">Liens utiles</div>
      <div className="marge">
        <a href="/devis" className="footer-link">
          <u>- Devis gratuit</u>
        </a>
      </div>
      <div className="marge">
        <a
          href="https://espace.choisir-son-expert-comptable.fr/"
          className="footer-link"
        >
          <u>- Espace client</u>
        </a>
      </div>
      <div className="text-blue marge">Suivez-nous</div>
      <div className="marge social">
        <div className="">
          <Link
            to="https://www.facebook.com/people/CEC-Choisir-son-Expert-Comptable/61551122296760/"
            className="footer-link"
          >
            <img className="solution-icons-s" src={fb} alt="Logo Facebook" />
          </Link>
        </div>
        <div className="icons-s">
          <Link
            to="https://www.linkedin.com/company/cec-choisir-son-expert-comptable/?lipi=urn%3Ali%3Apage%3Ad_flagship3_feed%3Bvb33L3C7Qu6YM4ypzQaw5g%3D%3D"
            className="footer-link"
          >
            <img
              className="solution-icons-s"
              src={linkedin}
              alt="Logo LinkedIn"
            />
          </Link>
        </div>
        <div className="icons-s">
          <Link
            to="https://www.instagram.com/choisirsonexpertcomptable/"
            className="footer-link"
          >
            <img
              className="solution-icons-s"
              src={insta}
              alt="Logo Instagram"
            />
          </Link>
        </div>
      </div>
    </div>

    <div className="footer-4">
      <div className="text-blue marge">Vous êtes expert-comptable</div>
      <div className="marge">
        Contactez-nous directement pour une éventuelle affilation.
      </div>
      <div className="marge btn-footer">
        <Link to="/contact" className="footer-link">
          Contactez-nous
        </Link>
      </div>
    </div>
  </div>
);

export default Footerextented;
