import React from 'react';
import PropTypes from 'prop-types';
import { useCombobox } from 'downshift';
import '../../css/autocomplete/autocomplete.css';

function ComboBox({
  allData,
  label,
  placeholder,
  setSelectedItem,
  getLocationsFilter,
  enableCreation,
  selectedItem,
}) {
  const [items, setItems] = React.useState([]);
  const {
    isOpen,
    inputValue,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getItemProps,
  } = useCombobox({
    selectedItem,
    onInputValueChange({ inputValue }) {
      const inputValueSanitized = inputValue.trim();

      if (inputValueSanitized === '') {
        setSelectedItem(null);
        setItems([]);

        return;
      }

      const filteredItems = allData.filter(
        getLocationsFilter(inputValueSanitized)
      );

      if (enableCreation && filteredItems.length === 0) {
        setItems([{ value: inputValueSanitized, isCreation: true }]);
      } else {
        setItems(filteredItems);
      }
    },

    onSelectedItemChange: (item) => {
      if (enableCreation && item?.selectedItem?.isCreation) {
        // Créez votre élément ici, par exemple, ajoutez-le à `allData`
        allData.push(item?.selectedItem.value);
        setItems([item?.selectedItem.value]);
      }

      setSelectedItem(item?.selectedItem);
    },

    items,
    itemToString(item) {
      if (item?.isCreation) {
        return item.value;
      }

      return item || '';
    },
  });

  return (
    <div className="dropdown-wrapper">
      <div>
        <label className="devis-form-label" {...getLabelProps()}>
          {label}
        </label>
        <div>
          <input
            placeholder={placeholder}
            className="comboBox"
            {...getInputProps()}
          />
        </div>
      </div>
      <ul
        className={`combobox__dropdown-menu ${
          !(isOpen && items.length) ? 'hidden' : ''
        }`}
        {...getMenuProps()}
      >
        {isOpen &&
          items.length > 0 &&
          items.map((item, index) => (
            <li
              key={`${item.value}-${index}`}
              {...getItemProps({ item, index })}
            >
              <span className="dropdown-option">
                {item.isCreation ? `Ajouter ${item.value}` : item}
              </span>
            </li>
          ))}
      </ul>
      {isOpen && items.length === 0 && inputValue?.length >= 3 && (
        <span className="auto-complete-red">
          Aucun résultat trouvé pour la recherche{' '}
          <span className="aut-complete-red-bold">"{inputValue}"</span>
        </span>
      )}
    </div>
  );
}

const SearchComponent = ({
  allData,
  label,
  placeholder,
  setSelectedItem,
  enableCreation,
  selectedItem,
}) => {
  function getLocationsFilter(inputValue) {
    const lowerCasedInputValue = inputValue.toLowerCase();

    return function locationFilter(item) {
      return !inputValue || item.toLowerCase().startsWith(lowerCasedInputValue);
    };
  }

  return (
    <ComboBox
      allData={allData}
      label={label}
      placeholder={placeholder}
      setSelectedItem={setSelectedItem}
      getLocationsFilter={getLocationsFilter}
      enableCreation={enableCreation}
      selectedItem={selectedItem}
    />
  );
};

SearchComponent.propTypes = {
  allData: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  setSelectedItem: PropTypes.func.isRequired,
  enableCreation: PropTypes.bool,
  selectedItem: PropTypes.string,
};

ComboBox.propTypes = {
  allData: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  setSelectedItem: PropTypes.func.isRequired,
  getLocationsFilter: PropTypes.func.isRequired,
  enableCreation: PropTypes.bool,
  selectedItem: PropTypes.string,
};

export default SearchComponent;
