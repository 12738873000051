import API from '../../utils/axios.js';

const quotesByClientID = async (clientId) => {
  const { data } = await API.get(`quotes/clients/${clientId}`);

  return data;
};

const getExpertInfoById = async (id) => {
  const { data } = await API.get(`/quotes/expert/${id}`);

  return data;
};

const quoteService = {
  quotesByClientID,
  getExpertInfoById,
};

export default quoteService;
