/* eslint-disable no-param-reassign */
import React from 'react';
import { ToastContext } from './contexts.js';
import toast, { toastConfig } from 'react-simple-toasts';
import ToastElement from '../Toasts/ToastElement.js';
import ToastsOptions from '../Toasts/ToastOptions/toastOptions.js';
import PropTypes from 'prop-types';

toastConfig({
  ...ToastsOptions.DefaultToastOptions,
});

function ToastProvider({ children }) {
  function enqueueToast(message, options) {
    options = checkTheme(options);
    options = setRenderProps(message, options);
    options = checkMaxVisible(options);
    options = checkPosition(options);

    return toast(message, options);
  }

  function checkTheme(options) {
    return {
      ...options,
      ...{ theme: ToastsOptions.ToastThemes.verifyTheme(options.theme) },
    };
  }

  function setRenderProps(message, options) {
    const renderProps = {};

    if (options?.header) {
      renderProps.header = options.header;
      delete options.header;
    }

    renderProps.theme = options.theme;

    renderProps.message = message;

    return {
      ...options,
      ...{ render: () => <ToastElement {...renderProps} /> },
    };
  }

  function checkMaxVisible(options) {
    if (options?.maxVisibleToasts) return options;

    return {
      ...options,
      ...{
        maxVisibleToasts: ToastsOptions.DefaultToastOptions.maxVisibleToasts,
      },
    };
  }

  function checkPosition(options) {
    return {
      ...options,
      ...{ position: ToastsOptions.verifyPosition(options.position) },
    };
  }

  return (
    <ToastContext.Provider
      value={{
        enqueueToast,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
}

ToastProvider.propTypes = {
  children: PropTypes.node,
};

export default ToastProvider;
