import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import AnimatedStep from './AnimatedStep.js';
import Step1 from './step1.js';
import Step2 from './step2.js';
import Step3 from './step3.js';
import Step4 from './step4.js';
import Step5 from './step5.js';
import Step6 from './step6.js';
import Step7 from './step7.js';
import Step8 from './step8.js';
import '../../../css/devis/devis.css';
import background1 from '../../../css/images/background-3.jpg';
import Header from '../../Template/Header.js';
import Footer from '../../Template/Footer.js';
import Footerextented from '../../Template/Footerextented.js';
import { HashLink } from 'react-router-hash-link';
import { motion } from 'framer-motion';
import axios from 'axios';
import useToast from '../../Toasts/useToast.js';
import ToastsOptions, {
  ToastThemes,
  ToastMessages,
  ToastHeaders,
} from '../../Toasts/ToastOptions/toastOptions.js';
import { useNavigate } from 'react-router-dom';
import Step0 from './step0.js';
import model from '../model/formData.js';
import SEO from '../../SEO/seo.js';

const API_PATH =
  process.env.REACT_APP_API_URL ||
  'https://api.choisir-son-expert-comptable.fr/api/';

const maxSteps = 8;
const speed = 10;

function Devis(props) {
  const initialRender = useRef(true);
  const [currentStep, setCurrentStep] = useState(0);
  const elRefs = useRef([]);
  const [stepJson, setStepJson] = useState(model.formData);
  const [currentExpert, setCurrentExpert] = useState(0);
  const [expertIds, setExpertIds] = useState([]);
  const [firstFetch, setFirstFetch] = useState(true);

  const navigate = useNavigate();
  const enqueueToast = useToast();

  useEffect(() => {
    increaseProgress();
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [currentStep]);

  useEffect(() => {
    fetchExperts();
  }, []);

  useEffect(() => {
    if (currentStep === 7) return;

    if (initialRender.current) {
      initialRender.current = false;
    } else {
      fetchExperts();
    }
  }, [stepJson]);

  useEffect(() => {
    if (stepJson.clientId) {
      setCurrentStep(currentStep + 1);
    } else if (
      currentStep === 7 &&
      stepJson.readyToSubmit &&
      !stepJson.clientId
    ) {
      elRefs.current[7].RequestClientCreation();
    }
  }, [stepJson]);

  const fetchExperts = useCallback(async () => {
    const paramsObject = createParamsObject();

    if (currentStep === 7) return;

    try {
      const [
        expertIDsArray,
        filteredByActivities,
        filteredBySkills,
        filteredByCities,
      ] = await Promise.all([
        getExpertIds(paramsObject),
        filterExpertsByActivities(),
        filterExpertsBySkills(),
        filterExpertsByCities(),
      ]);

      const filteredExpertIds = expertIDsArray.filter(
        (id) =>
          (filteredByActivities === null ||
            filteredByActivities.includes(id)) &&
          (filteredBySkills === null || filteredBySkills.includes(id)) &&
          (filteredByCities === null || filteredByCities.includes(id))
      );
      handleExpertResults(filteredExpertIds);
    } catch (error) {
      enqueueToast(ToastMessages.ERROR_OCCURED_FETCH, {
        theme: ToastThemes.toastThemes.ERROR_OCCURED,
        header: ToastHeaders[ToastThemes.toastThemes.ERROR_OCCURED],
        duration: 3000,
      });
      console.error(`Error: ${error}`);
    }
  }, [stepJson]);

  const createParamsObject = () => ({
    search: {
      nbNewClients: { gte: 1 },
      isOpen: 1,
      acceptedLegalForms: {
        id: stepJson.chosenLegalForm,
      },
      acceptedSectors: {
        id: stepJson.chosenSector,
      },
      lastName: {
        notLike: stepJson.nomExpertComptable,
      },
      location: {
        country: stepJson.country,
      },
    },
    field: { expert: 'id' },
  });

  const getExpertIds = async (paramsObject) => {
    const response = await axios.get(`${API_PATH}experts`, {
      params: paramsObject,
    });

    return response.data?.map((expert) => expert?.id) || [];
  };

  const filterExpertsByActivities = async () => {
    if (!stepJson?.chosenActivitiesData?.length) return null;

    const response = await axios.post(`${API_PATH}experts/activities`, {
      activities: stepJson.chosenActivitiesData,
    });

    return response.data?.map((expert) => expert?.id) || [];
  };

  const filterExpertsBySkills = async () => {
    if (!stepJson?.chosenSkills?.length) return null;

    const response = await axios.post(`${API_PATH}experts/skills`, {
      skills: stepJson.chosenSkills,
    });

    return response.data?.map((expert) => expert?.id) || [];
  };

  const filterExpertsByCities = async () => {
    if (!stepJson?.chosenCity?.length) return null;

    const response = await axios.post(`${API_PATH}experts/locations`, {
      locations: stepJson.chosenCity,
    });

    return response.data?.map((expert) => expert?.id) || [];
  };

  const handleExpertResults = (expertIDsArray) => {
    const nbExperts = expertIDsArray.length;
    setExpertIds(expertIDsArray); // Mettez à jour expertIds ici
    incEltNbr('nbr', nbExperts);

    if (!nbExperts) {
      enqueueToast(ToastMessages.NO_EXPERT, {
        theme: ToastThemes.toastThemes.NO_EXPERT,
        // header: ToastHeaders[ToastThemes.toastThemes.NO_EXPERT],
        position: ToastsOptions.ToastPositions.CENTER,
        duration: Infinity,
        clickClosable: true,
      });
      const toastElement = document.querySelector(`.toast-enter-active`);

      if (toastElement) {
        toastElement?.classList?.add('toast-enter-active-no-expert');
      }
    } else if (firstFetch) {
      setFirstFetch(false);
    } else {
      if (currentStep + 1 >= maxSteps) {
        document.getElementById('continuer')?.classList?.add('remove-btn');
        document.getElementById('retour')?.classList?.add('remove-btn');
      }

      let additionalStepSkip = 1;

      if (currentStep + additionalStepSkip === 2 && stepJson.isCreation) {
        additionalStepSkip++;
      }

      if (currentStep + additionalStepSkip === 4) additionalStepSkip++;

      if (currentStep + additionalStepSkip === 6 && stepJson.isCreation)
        additionalStepSkip++;

      setCurrentStep(currentStep + additionalStepSkip);
    }
  };

  function GetClasses(stepNumber) {
    return currentStep === stepNumber ? '' : 'hide';
  }

  function GetStepId(stepNumber) {
    return `form-step-${stepNumber}`;
  }

  function increaseProgress() {
    let progression = (currentStep / maxSteps) * 100;

    const elm = document.querySelector('.progress-fill');

    if (progression > 100) {
      progression = 100;
    }
    elm.style.width = `${progression}%`;
  }

  async function onContinueClick() {
    const currentRef = elRefs.current[currentStep];
    const validationResult = await currentRef.validateStep();

    if (!validationResult?.error) {
      setStepJson((prevStepJson) => ({
        ...prevStepJson,
        ...validationResult?.data,
      }));
    }
  }

  function callRequestClientCreation() {
    const currentRef = elRefs.current[currentStep];
    currentRef
      .validateStep()
      .then((response) => {
        if (typeof response === 'object') {
          if (!response?.error)
            setStepJson((prevStepJson) => ({
              ...prevStepJson,
              ...response?.data,
              readyToSubmit: true,
            }));
        }
      })
      .catch((error) => {
        enqueueToast(ToastMessages.ERROR_OCCURED_FETCH, {
          theme: ToastThemes.toastThemes.ERROR_OCCURED,
          header: ToastHeaders[ToastThemes.toastThemes.ERROR_OCCURED],
          duration: 3000,
        });
        console.error(error);
      });
  }

  /* function callRequestAccountCreation() {
    elRefs.current[8].RequestAccountCreation();
  } */

  /* Call this function with a string containing the ID name to
   * the element containing the number you want to do a currentStep animation on.*/
  // Id : Define the element identifier
  // nbExperts : Define the final expert currentStep after the search result
  function incEltNbr(id, nbExperts) {
    const element = document.getElementById(id);
    incNbrRec(currentExpert, nbExperts, element);

    setCurrentExpert(nbExperts);
  }

  /* A recursive function to increase the number.*/
  function incNbrRec(i, nbExperts, element) {
    if (i >= nbExperts) {
      element.textContent = i;
      setTimeout(() => {
        // Delay a bit before calling the function again.
        incNbrRec(i - 1, nbExperts, element);
      }, speed);
    }
  }

  function btn() {
    return (
      <div className="devis-btn-steps-all">
        <button
          className="devis-btn-steps"
          id="continuer"
          onClick={() => onContinueClick()}
        >
          Continuer
        </button>
        {currentStep !== 0 && (
          <button
            className="devis-backward-steps"
            id="retour"
            onClick={() => ReturnStepClick()}
          >
            Retour
          </button>
        )}
      </div>
    );
  }

  function updateCliendId(clientId) {
    setStepJson((prevStepJson) => ({
      ...prevStepJson,
      clientId,
    }));
  }

  const ReturnStepClick = () => {
    let additionalStepBack = 1;

    if (currentStep - additionalStepBack === 6 && stepJson.isCreation)
      additionalStepBack++;

    if (currentStep - additionalStepBack === 4) additionalStepBack++;

    if (currentStep - additionalStepBack === 2 && stepJson.isCreation)
      additionalStepBack++;

    setCurrentStep(
      currentStep - additionalStepBack > 0
        ? currentStep - additionalStepBack
        : 0
    );
  };

  /* function btnUserCreation() {
    return (
      <div className="devis-btn-wrapper">
        <button
          className="devis-creer-compte"
          id="accurrentStepCreation"
          onClick={() => callRequestAccountCreation()}
        >
          Je souhaite créer un compte
        </button>
      </div>
    );
  }

  function btnHome() {
    return (
      <div className="devis-btn-wrapper">
        <button
          className="devis-retour-accueil"
          id="homeButton"
          onClick={() => navigate('/')}
        >
          Revenir à l&apos;accueil
        </button>
      </div>
    );
  } */

  return (
    <motion.div
      initial={{ position: 'relative', top: '100vh', left: 0 }}
      animate={{ top: 0 }}
      transition={{ duration: 0.75, easing: 'ease' }}
    >
      <SEO
        pageType="website"
        pageTitle="Choisir Son Expert Comptable - CEC - Devis"
        pageDescription="Obtenez 3 devis pour booster votre entreprise. Trouvez votre expert-comptable facilement."
        pageURL="https://www.choisir-son-expert-comptable.fr/devis"
      />
      <Header pageName={props.pageName} />
      <div className="background-reduce">
        <img className="banner" src={background1} alt="" />
      </div>
      <div className="devis-container">
        <div className="devis-left-column">
          <h2 className="devis-title">Obtenir mes 3 devis gratuits !</h2>
        </div>
        <div className="devis-right-column">
          <div className="devis-container-steps">
            <div className="devis-container-progress">
              <div className="progress-bar-steps">
                <div className="progress-fill"></div>
              </div>
              <div className="devis-progress-number">
                <div id="nbr">{currentExpert}</div>
                <div id="experts">experts dispo</div>
              </div>
            </div>

            <AnimatedStep
              classes={GetClasses(0)}
              id={GetStepId(0)}
              currentStep={currentStep}
            >
              <Step0 ref={(el) => (elRefs.current[0] = el)} model={stepJson} />
            </AnimatedStep>
            <AnimatedStep
              classes={GetClasses(1)}
              id={GetStepId(1)}
              currentStep={currentStep}
            >
              <Step1
                ref={(el) => (elRefs.current[1] = el)}
                model={stepJson}
                currentStep={currentStep}
              />
            </AnimatedStep>
            <AnimatedStep
              classes={GetClasses(2)}
              id={GetStepId(2)}
              currentStep={currentStep}
            >
              <Step2
                ref={(el) => (elRefs.current[2] = el)}
                model={stepJson}
                currentStep={currentStep}
              />
            </AnimatedStep>
            <AnimatedStep
              classes={GetClasses(3)}
              id={GetStepId(3)}
              currentStep={currentStep}
            >
              <Step3
                ref={(el) => (elRefs.current[3] = el)}
                model={stepJson}
                currentStep={currentStep}
              />
            </AnimatedStep>
            <AnimatedStep
              classes={GetClasses(4)}
              id={GetStepId(4)}
              currentStep={currentStep}
            >
              <Step4 ref={(el) => (elRefs.current[4] = el)} model={stepJson} />
            </AnimatedStep>
            <AnimatedStep
              classes={GetClasses(5)}
              id={GetStepId(5)}
              currentStep={currentStep}
            >
              <Step5 ref={(el) => (elRefs.current[5] = el)} model={stepJson} />
            </AnimatedStep>
            <AnimatedStep
              classes={GetClasses(6)}
              id={GetStepId(6)}
              currentStep={currentStep}
            >
              <Step6 ref={(el) => (elRefs.current[6] = el)} model={stepJson} />
            </AnimatedStep>
            <AnimatedStep
              classes={GetClasses(7)}
              id={GetStepId(7)}
              currentStep={currentStep}
            >
              <Step7
                ref={(el) => (elRefs.current[7] = el)}
                model={stepJson}
                updateCliendId={updateCliendId}
                expertIds={expertIds}
                ReturnStepClick={ReturnStepClick}
                callRequestClientCreation={callRequestClientCreation}
              />
            </AnimatedStep>
            <AnimatedStep
              classes={GetClasses(8)}
              id={GetStepId(8)}
              currentStep={currentStep}
            >
              <Step8
                ref={(el) => (elRefs.current[8] = el)}
                model={stepJson}
                navigate={navigate}
              />
            </AnimatedStep>
            {currentStep <= 6 && btn()}
            {/* {currentStep === 8 && (
              <>
                {btnUserCreation()}
                {btnHome()}
              </>
            )} */}
          </div>
        </div>
        <p className="devis-subtext">
          Obtenez 3 devis sur mesure pour votre entreprise. Choisissez le
          meilleur sur votre espace personnel et fixez des rendez-vous sans
          engagement avec l'expert-comptable sélectionné. Simplifiez vos choix,
          boostez votre entreprise.
        </p>
        <div className="devis-btn-link btn-center">
          <HashLink to="tel:0766207752" className="devis-btn-help">
            Besoin d'aide ?
            <br /> 07 66 20 77 52
          </HashLink>
        </div>
      </div>
      <Footerextented />
      <Footer />
    </motion.div>
  );
}

Devis.propTypes = {
  pageName: PropTypes.string,
};

export default Devis;
