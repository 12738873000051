import React, { useContext, useEffect, useState, useCallback } from 'react';
import QuotesCards from './QuotesCards.js';
import { ClientContext } from '../Contexts/contexts.js';
import activityServices from '../../utils/activity.js';
import quotesServices from './quote.service.js';
import useToast from '../Toasts/useToast.js';
import {
  ToastHeaders,
  ToastThemes,
  ToastMessages,
} from '../Toasts/ToastOptions/toastOptions.js';
import PropTypes from 'prop-types';
import { status } from '../../static/data/fetchStatus.js';
import '../../css/quotes/quoteElementUser.css';
import '../../css/quotes/quotes.css';

function Quotes(props) {
  const clientContext = useContext(ClientContext);
  const [activities, setActivities] = useState([]);
  const [quotes, setQuotes] = useState([]);
  const [activitiesFetched, setActivitiesFetched] = useState(status.DEFAULT);
  const [quotesFetched, setQuotesFetched] = useState(status.DEFAULT);

  const enqueueToast = useToast();

  const fetchActivities = useCallback(async () => {
    if (
      clientContext?.clientId === undefined ||
      activitiesFetched === status.FETCHED
    )
      return;

    setActivitiesFetched(status.FETCHED);

    try {
      const data = await activityServices.findActivities();

      if (data.length === 0) {
        return;
      }

      const uncalculatedActivities = data.filter(
        (activity) => activity.isCalculated === false
      );

      setActivities(uncalculatedActivities);
    } catch (error) {
      enqueueToast(ToastMessages.ERROR_OCCURED_FETCH, {
        theme: ToastThemes.toastThemes.ERROR_OCCURED,
        header: ToastHeaders[ToastThemes.toastThemes.ERROR_OCCURED],
        duration: 3000,
      });
      console.error(error);
    }
  }, [enqueueToast, activitiesFetched]);

  useEffect(() => {
    fetchActivities();
  }, [fetchActivities]);

  const fetchQuotes = useCallback(async () => {
    if (
      clientContext?.clientId === undefined ||
      quotesFetched === status.FETCHED
    )
      return;

    setQuotesFetched(status.FETCHED);

    try {
      const data = await quotesServices.quotesByClientID(
        clientContext.clientId
      );

      if (data.length === 0) {
        return;
      }

      setQuotes(data);
    } catch (error) {
      enqueueToast(ToastMessages.ERROR_OCCURED_FETCH, {
        theme: ToastThemes.toastThemes.ERROR_OCCURED,
        header: ToastHeaders[ToastThemes.toastThemes.ERROR_OCCURED],
        duration: 3000,
      });
      console.error(error);
    }
  }, [enqueueToast, quotesFetched]);

  useEffect(() => {
    fetchQuotes();
  }, [fetchQuotes]);

  function GetAvailableQuotes() {
    return quotes.sort((quoteA, quoteB) => quoteA.price - quoteB.price);
  }

  return (
    <div className="ts-container">
      <div className="ts-container-column">
        <div className="quote-cards">
          {clientContext &&
            clientContext?.clientExpertRelation === undefined &&
            GetAvailableQuotes()?.map((quote, idx) => {
              if (idx > 2) return null;

              return (
                <QuotesCards
                  data={quote}
                  id={`quote-card-${idx}`}
                  key={quote.id}
                  number={idx}
                  requestAccountCreation={props?.requestAccountCreation}
                />
              );
            })}
        </div>
        <div className="quote-service-disclaimer">
          * L'estimation ne prend pas en compte les services suivants :{' '}
          <span>
            {activities.map((activity) => activity.description).join(', ')}
          </span>
          {/* */}. Leurs coûts nécessitent un examen approfondi de la part de
          l'expert.
        </div>
      </div>
    </div>
  );
}

// Full service TPE, Optimisation Fiscale, Conseil entreprise en difficulté,
// Etude de marché, Evaluation d'entreprise, DAF/CFO externalisé, RSE,
// Consolidation, Fusion Acquisition, Rachat d'entreprise, Transmission
// d'entreprise.

Quotes.propTypes = {
  requestAccountCreation: PropTypes.func.isRequired,
};

export default Quotes;
