import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import axios from 'axios';
import '../../../css/devis/devis.css';
import '../../../css/devis/step6.css';
import useToast from '../../Toasts/useToast.js';
import {
  ToastMessages,
  ToastHeaders,
  ToastThemes,
} from '../../Toasts/ToastOptions/toastOptions.js';
import PropTypes from 'prop-types';

const API_PATH =
  process.env.REACT_APP_API_URL ||
  'https://api.choisir-son-expert-comptable.fr/api/';

const Step5 = forwardRef((props, ref) => {
  const [chosenSector, setChosenSector] = useState('');
  const [nbEmployees, setNbEmployees] = useState(0);
  const [consultationType, setConsultationType] = useState('1');
  const [sectors, setSectors] = useState([]);
  const [turnover, setTurnover] = useState(0);
  const [nbConsultations, setNbConsultations] = useState('4');

  useImperativeHandle(ref, () => ({
    validateStep,
  }));

  const enqueueToast = useToast();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchSectors();
      } catch (error) {
        enqueueToast(ToastMessages.ERROR_OCCURED_FETCH, {
          theme: ToastThemes.toastThemes.ERROR_OCCURED,
          header: ToastHeaders[ToastThemes.toastThemes.ERROR_OCCURED],
          duration: 3000,
        });
        console.error('Erreur lors de la récupération des données', error);
      }
    };

    fetchData();
  }, []);

  const handleNbConsultationsChanged = (e) => {
    const { value } = e.target;

    setNbConsultations(value);
  };

  const handleValueChanged = (e) => {
    const { id, value } = e.target;

    const regex = /^[0-9\b]+$/;

    if (value === '' || regex.test(value)) {
      setTurnover(value === '' ? '' : Math.abs(value));
    }

    document.getElementById(id).classList.remove('devis-error');
  };

  const handleChangeSector = (e) => {
    const { value } = e.target;

    setChosenSector(value);

    const input = document.getElementById('sector');
    input.classList.remove('devis-error');
    input.setCustomValidity('');
  };

  const handleChangeConsultationType = (e) => {
    const { value } = e.target;

    setConsultationType(value);

    document.getElementById('consultationType').classList.remove('devis-error');
  };

  const handleNbEmployeesChanged = (e) => {
    const { id, value } = e.target;

    const regex = /^[0-9\b]+$/;

    if (value === '' || regex.test(value)) {
      setNbEmployees(value === '' ? '' : Math.abs(value));
    }

    document.getElementById(id).classList.remove('devis-error');
  };

  const handleKeyPress = (e) => {
    // Autoriser les touches de contrôle
    if (
      e.which === 0 ||
      e.which === 8 ||
      e.which === 46 ||
      e.keyCode === 37 || // Flèche gauche
      e.keyCode === 38 || // Flèche haut
      e.keyCode === 39 || // Flèche droite
      e.keyCode === 40
    ) {
      return;
    }

    // Vérifie si le caractère n'est pas un chiffre (0-9) et empêche l'entrée
    if (!e.key.match(/\d/)) {
      e.preventDefault();
    }
  };

  const fetchSectors = async () => {
    try {
      const response = await axios.get(`${API_PATH}sectors`);
      const sectorsData = response.data;

      setSectors(sectorsData);
    } catch (error) {
      enqueueToast(ToastMessages.ERROR_OCCURED_FETCH, {
        theme: ToastThemes.toastThemes.ERROR_OCCURED,
        header: ToastHeaders[ToastThemes.toastThemes.ERROR_OCCURED],
        duration: 3000,
      });
      console.error('Erreur lors de la récupération des données', error);
    }
  };

  const validateStep = () => {
    let firstInvalidInput = null;
    let hasError = false;
    let inputWithError = null;

    if (!chosenSector) {
      inputWithError = document.getElementById('sector');
      inputWithError.classList.add('devis-error');
      hasError = true;
      if (!firstInvalidInput) firstInvalidInput = inputWithError;
    }

    if (nbEmployees === '' || nbEmployees < 0) {
      inputWithError = document.getElementById('nbEmployees');
      inputWithError.classList.add('devis-error');
      hasError = true;
      if (!firstInvalidInput) firstInvalidInput = inputWithError;
    }

    if (!consultationType) {
      inputWithError = document.getElementById('consultationType');
      inputWithError.classList.add('devis-error');
      hasError = true;
      if (!firstInvalidInput) firstInvalidInput = inputWithError;
    }

    if (turnover === '' || turnover < 0) {
      inputWithError = document.getElementById('turnover');
      inputWithError.classList.add('devis-error');
      hasError = true;
      if (!firstInvalidInput) firstInvalidInput = inputWithError;
    }

    if (!nbConsultations) {
      inputWithError = document.getElementById('nbConsultations');
      inputWithError.classList.add('devis-error');
      hasError = true;
      if (!firstInvalidInput) firstInvalidInput = inputWithError;
    }

    const result = {
      error: hasError,
      data: {
        chosenSector,
        nbEmployees,
        consultationType,
        turnover,
        nbConsultations,
      },
    };

    if (hasError) {
      firstInvalidInput?.focus();
      firstInvalidInput?.reportValidity();
    }

    return result;
  };

  return (
    <>
      <label htmlFor="sector" className="devis-form-label">
        Votre secteur d&apos;activité
      </label>
      <select
        name="sector"
        id="sector"
        onChange={handleChangeSector}
        className="devis-form-input"
        value={chosenSector}
      >
        <option value="">Choisissez un secteur d&apos;activité</option>
        {sectors
          ?.sort((activityTaskA, activityTaskB) =>
            activityTaskA.description.localeCompare(activityTaskB.description)
          )
          .map((sector) => (
            <option key={sector.id} value={sector.id}>
              {sector.name}
            </option>
          ))}
      </select>
      {!props.model?.isCreation && (
        <>
          <div className="devis-medium-input">
            <label htmlFor="nbEmployees" className="devis-form-label">
              Nombres de salariés{' '}
            </label>
            <input
              id="nbEmployees"
              onChange={handleNbEmployeesChanged}
              value={nbEmployees}
              type="number"
              onKeyDown={handleKeyPress}
            />
          </div>
          <div className="devis-medium-input">
            <label htmlFor="turnover" className="devis-form-label">
              Départs et arrivées de salariés durant l&apos;année
            </label>
            <input
              type="number"
              id="turnover"
              value={turnover}
              onChange={handleValueChanged}
              onKeyDown={handleKeyPress}
            />
          </div>

          <span className="devis-form-span">
            Type de rendez-vous préférentiel
          </span>
          <div id="consultationType" className="devis-radio-btn">
            <div>
              <input
                type="radio"
                name="consultationType"
                value="1"
                onChange={handleChangeConsultationType}
                checked={consultationType === '1'}
              />
              {/* */}
              Physique
            </div>
            <div>
              <input
                type="radio"
                name="consultationType"
                value="2"
                onChange={handleChangeConsultationType}
                checked={consultationType === '2'}
              />
              {/* */}
              Visioconférence
            </div>
          </div>
          <span className="devis-form-span">
            Périodicité des entretiens avec l&apos;expert
          </span>
          <div className="devis-radio-btn">
            <div>
              <input
                type="radio"
                name="nbConsultations"
                value="4"
                onChange={handleNbConsultationsChanged}
                checked={nbConsultations === '4'}
              />
              {/* */}
              Annuel
            </div>
            <div>
              <input
                type="radio"
                name="nbConsultations"
                value="3"
                onChange={handleNbConsultationsChanged}
                checked={nbConsultations === '3'}
              />
              {/* */}
              Semestriel
            </div>
            <div>
              <input
                type="radio"
                name="nbConsultations"
                value="2"
                onChange={handleNbConsultationsChanged}
                checked={nbConsultations === '2'}
              />
              {/* */}
              Trimestriel
            </div>
            <div>
              <input
                type="radio"
                name="nbConsultations"
                value="1"
                onChange={handleNbConsultationsChanged}
                checked={nbConsultations === '1'}
              />
              {/* */}
              Mensuel
            </div>
          </div>
        </>
      )}
    </>
  );
});

Step5.propTypes = {
  model: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

Step5.displayName = 'Step5';

export default Step5;
