import React, { forwardRef, useImperativeHandle, useState } from 'react';
import economicServiceLevelIcon from '../../../css/images/economic_service_level.svg';
import standardServiceLevelIcon from '../../../css/images/standard_service_level.svg';
import premiumServiceLevelIcon from '../../../css/images/premium_service_level.svg';
import '../../../css/devis/devis.css';
import PropTypes from 'prop-types';

const Step4 = forwardRef((props, ref) => {
  const [serviceLevel, setServiceLevel] = useState('1');

  useImperativeHandle(ref, () => ({
    validateStep,
  }));

  const onValueChange = (e) => {
    const { value } = e.target;

    let temp = serviceLevel;

    if (temp !== '') {
      const elem = document.getElementById(`serviceLevel-${temp}`);
      elem.checked = false;
    }

    temp = value;

    Array.from(document.getElementsByClassName('devis-cc-input')).forEach(
      (element) => {
        element.classList.remove('devis-cc-input-error');
      }
    );

    setServiceLevel(temp);
  };

  const validateStep = () => {
    let hasError = false;
    let inputWithError = null;

    if (serviceLevel === '') {
      Array.from(document.getElementsByClassName('devis-cc-input')).forEach(
        (element) => {
          inputWithError = element;
          inputWithError.classList.add('devis-cc-input-error');
        }
      );
      hasError = true;
    }

    const result = {
      error: hasError,
      data: serviceLevel,
    };

    if (hasError) {
      inputWithError?.focus();
      inputWithError?.reportValidity();
    }

    return result;
  };

  return (
    <form id="form-prestations" className="devis-form-prestations">
      <span className="devis-form-label">Type de prestation attendue</span>
      <div className="devis-card-checkbox">
        <img className="devis-cc-icon" src={economicServiceLevelIcon} alt="" />
        <div className="devis-cc-content">
          <label htmlFor="serviceLevel-0" className="devis-card-label">
            Economique
          </label>
          <span className="devis-form-text">
            Expert proposant les prix les plus bas en respectant la déontologie
            de l&apos;Ordre des Experts comptable
          </span>
          <input
            className="devis-cc-input"
            type="checkbox"
            id="serviceLevel-0"
            name="serviceLevel"
            value="0"
            onChange={onValueChange}
            checked={serviceLevel === '0'}
          />
        </div>
      </div>
      <div className="devis-card-checkbox">
        <img className="devis-cc-icon" src={standardServiceLevelIcon} alt="" />
        <div className="devis-cc-content">
          <label htmlFor="serviceLevel-1" className="devis-card-label">
            Standard
          </label>
          <span className="devis-form-text">
            Meilleur rapport qualité prix pour votre type de société; une
            balance idéale entre conseil et prix raisonnable
          </span>
          <input
            className="devis-cc-input"
            type="checkbox"
            id="serviceLevel-1"
            name="serviceLevel"
            value="1"
            onChange={onValueChange}
            checked={serviceLevel === '1'}
          />
        </div>
      </div>
      <div className="devis-card-checkbox">
        <img className="devis-cc-icon" src={premiumServiceLevelIcon} alt="" />
        <div className="devis-cc-content">
          <label htmlFor="serviceLevel-2" className="devis-card-label">
            Premium
          </label>
          <span className="devis-form-text">
            Expert proposant un accompagnement supérieur, des conseils et suivis
            dès que vous en avez besoin
          </span>
          <input
            className="devis-cc-input"
            type="checkbox"
            id="serviceLevel-2"
            name="serviceLevel"
            value="2"
            onChange={onValueChange}
            checked={serviceLevel === '2'}
          />
        </div>
      </div>
    </form>
  );
});

Step4.propTypes = {
  model: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

Step4.displayName = 'Step4';

export default Step4;
