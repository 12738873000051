const toastThemes = {
  INFO: 'info',
  CONFIRM_SUCCESS: 'confirm-success',
  ERROR_OCCURED: 'error-occured',
  SEND_CONTACT: 'send-contact',
  NO_EXPERT: 'no-expert',
  GREETINGS: 'greetings',
};

function verifyTheme(theme) {
  return Object.values(toastThemes).includes(theme) ? theme : toastThemes.INFO;
}

const themes = {
  toastThemes,
  verifyTheme,
};

export default themes;
