import React from 'react';
import PropTypes from 'prop-types';
import '../../css/toasts/toasts.css';

function ToastElement(props) {
  return (
    <>
      <div className={`toast-background-${props?.theme}`}></div>
      <div className={`toast-component-${props?.theme}`}>
        {props?.header && (
          <div className={`toast-header-${props?.theme}`}>{props?.header}</div>
        )}
        <div className={`toast-body-${props?.theme}`}>{props?.message}</div>
      </div>
    </>
  );
}

ToastElement.propTypes = {
  header: PropTypes.string,
  theme: PropTypes.string,
  message: PropTypes.string,
};

export default ToastElement;
