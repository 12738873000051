import Header from '../Header.js';
import Footer from '../Footer.js';
import Footerextented from '../Footerextented.js';
import '../../../css/articles.css';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import loupe from '../../../css/images/loupe.svg';
import axios from 'axios';
import { dayMonthYearFormat } from '../../../utils/date.js';
import { Link } from 'react-router-dom';
import SEO from '../../SEO/seo.js';
import background1 from '../../../css/images/background-2.jpg';

const defaultStrapiGetUrl = `${process.env.REACT_APP_STRAPI_API_URL}articles?sort=Publication_date:desc`;

function Articles(props) {
  const [activityType, setActivityType] = useState('default');

  const [search, setSearch] = useState('');

  const [articles, setArticles] = useState([]);

  const [isSearching, setIsSearching] = useState(false);

  const [searchChanged, setSearchChanged] = useState(false);

  const getLastArticles = async () => {
    try {
      const { data } = await axios.get(defaultStrapiGetUrl, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_GET_TOKEN}`,
        },
      });

      setIsSearching(false);
      setArticles(data?.data || []);
    } catch (error) {
      console.error(error);
      setIsSearching(false);
    }
  };

  useEffect(() => {
    setIsSearching(true);
    getLastArticles();
  }, []);

  const onClickSelect = () => {
    const elmItems = document.getElementById('select-items');

    if (elmItems.classList.contains('select-hide'))
      elmItems.classList.remove('select-hide');
    else elmItems.classList.add('select-hide');
  };

  const onBlurSelect = () => {
    const elmItems = document.getElementById('select-items');

    if (!elmItems.classList.contains('select-hide'))
      elmItems.classList.add('select-hide');
  };

  const onClickSelectItem = (e) => {
    setActivityType(e.target.dataset.value);
    setSearchChanged(true);
  };

  const onSearchChanged = (e) => {
    setSearchChanged(true);
    setSearch(e.target.value);
  };

  const getTypeName = () => ActivityDomains[activityType];

  const onSearchSubmit = async (e) => {
    e.preventDefault();

    if (!searchChanged) return;

    const query = buildSearchQuery();

    setSearchChanged(false);
    setIsSearching(true);

    try {
      const { data } = await axios.get(`${defaultStrapiGetUrl}${query}`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_GET_TOKEN}`,
        },
      });

      setArticles(data?.data || []);
      setIsSearching(false);
    } catch (e) {
      console.error(e);
      setIsSearching(false);
    }
  };

  const buildSearchQuery = () => {
    let searchParams = '';

    if (activityType !== 'default' && search.length > 0)
      searchParams = `&filters[$or][0][Title][$containsi]=${search}&filters[$or][1][Description][$containsi]=${search}&filters[Type]=${activityType}`;

    if (search.length > 0 && activityType === 'default')
      searchParams = `&filters[$or][0][Title][$containsi]=${search}&filters[$or][1][Description][$containsi]=${search}`;

    if (activityType !== 'default' && search.length === 0)
      searchParams = `&filters[Type]=${activityType}`;

    return searchParams;
  };

  const getArticlesElement = () => {
    const elms = [];
    articles.forEach((article) => {
      elms.push(
        <article className="article-preview" key={article?.attributes?.Slug}>
          <div className="article-header">
            <span className="article-date">
              {dayMonthYearFormat(article?.attributes?.Publication_date)}
            </span>
            <span
              className={`article-activity activity-${article?.attributes?.Type}`}
            >
              {ActivityDomains[article?.attributes?.Type]}
            </span>
          </div>
          <Link
            to={`/articles/${article?.attributes?.Slug}`}
            className="article-title"
          >
            <h3>{article?.attributes?.Title}</h3>
          </Link>
          <p className="article-description">
            {article?.attributes?.Description}
          </p>
          <a
            href={`/articles/${article?.attributes?.Slug}`}
            className="article-link"
          >
            En savoir plus
          </a>
        </article>
      );
    });

    return elms;
  };

  return (
    <>
      <Header pageName={props.pageName} />
      <SEO
        pageType="website"
        pageTitle="Choisir Son Expert Comptable - CEC - Articles"
        pageDescription="Explorez articles et guides sur la comptabilité et finance. Boostez votre parcours d'entreprise."
        pageURL="https://www.choisir-son-expert-comptable.fr/articles/"
      />
      <div className="background-img">
        <img className="banner" src={background1} alt="" />
      </div>
      <div className="articles-page">
        <h2 className="articles-page-title">
          <span className="title-bluesoft">Choisir son expert-comptable :</span>{' '}
          Articles et informations
        </h2>
        <div className="articles-page-description">
          <p>
            <span className="strong">Découvrez nos articles :</span> sur la
            comptabilité, les investissements, la fiscalité, les conseils
            stratégiques et les dernières nouveautés.
          </p>
          <p>
            <span className="strong">Téléchargez nos guides :</span> Des outils
            concrets pour vous accompagner dans chaque étape de votre parcours
            financier.
          </p>
          <p>
            <span className="strong">Restez informé :</span> Bénéficiez de nos
            ressources exclusives pour prendre les meilleures décisions pour
            votre entreprise.
          </p>
        </div>
        <section className="articles-page-all articles-section">
          <form onSubmit={onSearchSubmit} className="articles-search-form">
            <input
              type="text"
              id="search"
              placeholder="Que recherchez-vous ?"
              autoComplete="off"
              name="search"
              className="articles-search-name"
              value={search}
              onChange={onSearchChanged}
            />
            <div
              className="articles-search-custom-select"
              id="custom-select"
              onClick={onClickSelect}
              onBlur={onBlurSelect}
              tabIndex={0}
            >
              <div className="select-selected" id="select-selected">
                {getTypeName()}
              </div>
              <div className="select-items select-hide" id="select-items">
                {Object.entries(ActivityDomains).map(([key, value]) => (
                  <div data-value={key} onClick={onClickSelectItem} key={key}>
                    {value}
                  </div>
                ))}
              </div>
            </div>
            <button
              type="submit"
              className="articles-search-submit"
              disabled={isSearching}
            >
              <span className="submit-search-text">Rechercher</span>
              <img
                src={loupe}
                alt="search-icon"
                className="submit-search-icon"
              />
            </button>
          </form>
        </section>
        <section className="articles-search-grid">
          {getArticlesElement()}
        </section>
      </div>
      <Footerextented />
      <Footer />
    </>
  );
}
export default Articles;

Articles.propTypes = {
  pageName: PropTypes.string,
};

const ActivityDomains = {
  default: "Domaine d'activité",
  Taxation: 'Fiscalité',
  National_law: 'Loi Nationale', // eslint-disable-line
  General_information: 'Informations Générales', // eslint-disable-line
  Accounting: 'Comptabilité',
};
