import React, { forwardRef, useState } from 'react';
import { Link } from 'react-router-dom';
import '../../css/contact.css';
import background1 from '../../css/images/background-3.jpg';
import useToast from '../Toasts/useToast.js';
import ToastsOptions, {
  ToastHeaders,
  ToastThemes,
  ToastMessages,
} from '../Toasts/ToastOptions/toastOptions.js';
import axios from 'axios';

const API_PATH =
  process.env.REACT_APP_API_URL ||
  'https://api.choisir-son-expert-comptable.fr/api/';

const Contact = forwardRef(() => {
  const [contactData, setContactData] = useState({
    lastName: '',
    firstName: '',
    subject: '',
    email: '',
    message: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const enqueueToast = useToast();

  const handleValueChange = (e) => {
    const { value } = e.target;
    const { id } = e.target;

    setContactData((prevState) => ({ ...prevState, [id]: value }));

    document.getElementById(id).classList.remove('contact-error');
  };

  const sendEmail = async () => {
    const { email, message, lastName, firstName, subject } = contactData;

    axios
      .post(`${API_PATH}emails/contact`, {
        message,
        email,
        lastName,
        firstName,
        subject,
      })
      .then(() => {
        enqueueToast(ToastMessages.SEND_CONTACT, {
          theme: ToastThemes.toastThemes.SEND_CONTACT,
          header: ToastHeaders[ToastThemes.toastThemes.SEND_CONTACT],
          duration: 300000,
          position: ToastsOptions.ToastPositions.BOTTOM_CENTER,
        });
        setContactData({
          lastName: '',
          firstName: '',
          subject: '',
          email: '',
          message: '',
        });

        setIsSubmitting(false);
      })
      .catch((error) => {
        enqueueToast(ToastMessages.ERROR_OCCURED_CONTACT, {
          theme: ToastThemes.toastThemes.ERROR_OCCURED,
          header: ToastHeaders[ToastThemes.toastThemes.ERROR_OCCURED],
          duration: 3000,
        });
        console.error(error);
        setIsSubmitting(false);
      });
  };

  const validationMessage = () => {
    let error = false;
    setIsSubmitting(true);

    for (const [key, value] of Object.entries(contactData)) {
      if (value === '') {
        document.getElementById(key).classList.add('contact-error');
        error = true;
      }
    }

    if (error) {
      setIsSubmitting(false);

      return;
    }

    sendEmail();
  };

  return (
    <div className="contact-container">
      <div className="contact-reviews-and-form">
        <div className="background-img">
          <img className="banner" src={background1} alt="" />
        </div>
        <div className="contact-container-infos">
          <div id="contactAn"></div>
          <h2 id="contact">
            <span className="title-bluesoft">Besoin de conseils</span>
            <br />
            pour choisir un expert-comptable ?
          </h2>
          <div>
            <p>
              Notre équipe est à votre disposition pour répondre à toutes vos
              questions et vous accompagner dans votre recherche
              d’expert-comptable.
            </p>
            <p>
              N'hésitez pas à nous contacter via le formulaire ci-dessous ou par
              téléphone
            </p>
          </div>
          <div className="contact-container-global">
            <div className="contact-contact-form">
              <div className="contact-infos">
                <Link to="#" className="contact-link">
                  Nous contacter directement
                </Link>
                <h3>Choisir son expert comptable</h3>
                <div className="contact-infos-row">
                  <p>contact@choisir-ec.fr</p>
                </div>
                <div className="contact-infos-row">
                  <p>+33 7 66 20 77 52</p>
                </div>
              </div>
              <div className="contact-container-form">
                <div className="contact-form-row">
                  <div className="contact-form-item">
                    <input
                      type="text"
                      id="lastName"
                      autoComplete="family-name"
                      value={contactData.lastName}
                      onChange={handleValueChange}
                    />
                    <label htmlFor="lastName">Nom</label>
                  </div>
                  <div className="contact-form-item">
                    <input
                      type="text"
                      id="firstName"
                      autoComplete="given-name"
                      value={contactData.firstName}
                      onChange={handleValueChange}
                    />
                    <label htmlFor="firstName">Prenom</label>
                  </div>
                </div>
                <div className="contact-form-row">
                  <div className="contact-form-item">
                    <input
                      type="text"
                      id="subject"
                      value={contactData.subject}
                      onChange={handleValueChange}
                    />
                    <label htmlFor="subject">Sujet de votre message</label>
                  </div>
                  <div className="contact-form-item">
                    <input
                      type="email"
                      id="email"
                      autoComplete="email"
                      value={contactData.email}
                      onChange={handleValueChange}
                    />
                    <label htmlFor="email">Email</label>
                  </div>
                </div>
                <div className="contact-form-textarea">
                  <textarea
                    id="message"
                    value={contactData.message}
                    onChange={handleValueChange}
                  ></textarea>
                  <label htmlFor="message">Votre message</label>
                </div>
                <button
                  type="button"
                  className="contact-btn"
                  onClick={validationMessage}
                  disabled={isSubmitting}
                >
                  Envoyer message
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

Contact.displayName = 'Contact';

export default Contact;
