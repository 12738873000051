export const status = {
  DEFAULT: 'DEFAULT',
  FETCHING: 'FETCHING',
  FETCHED: 'FETCHED',
  REFRESH: 'REFRESH',
};

export function shouldFetchData(verifyStatus) {
  return verifyStatus === status.DEFAULT || verifyStatus === status.REFRESH;
}
