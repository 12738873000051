/* eslint-disable no-underscore-dangle */
import axios from 'axios';

const API_PATH =
  process.env.REACT_APP_API_URL ||
  'https://api.choisir-son-expert-comptable.fr/api/';

const defaultConfig = {
  headers: {
    Authorization: `Bearer `,
  },
  responseType: 'json',
  authorization: true,
};

class API {
  static get = (url, config = defaultConfig) => {
    const finalConfig = { ...defaultConfig, ...config };

    return axios.get(`${API_PATH}${url}`, finalConfig);
  };

  static post = (url, data, config = defaultConfig) => {
    const finalConfig = { ...defaultConfig, ...config };

    return axios.post(`${API_PATH}${url}`, data, finalConfig);
  };

  static put = (url, data, config = defaultConfig) => {
    const finalConfig = { ...defaultConfig, ...config };

    return axios.put(`${API_PATH}${url}`, data, finalConfig);
  };

  static patch = (url, data, config = defaultConfig) => {
    const finalConfig = { ...defaultConfig, ...config };

    return axios.patch(`${API_PATH}${url}`, data, finalConfig);
  };

  static delete = (url, config = defaultConfig) => {
    const finalConfig = { ...defaultConfig, ...config };

    return axios.delete(`${API_PATH}${url}`, finalConfig);
  };

  static putFile = (url, data, config = defaultConfig) => {
    const finalConfig = { ...defaultConfig, ...config };
    delete finalConfig.headers.Authorization;

    return axios.put(`${url}`, data, finalConfig);
  };
}

export default API;
