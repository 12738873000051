const toastMessages = {
  CONFIRM_SUCCESS_APPOINTMENT:
    'Votre choix a bien été pris en compte, si aucun rendez- vous ne vous convenait, veuillez prendre contact avec votre expert pour vous organiser.',
  ERROR_OCCURED_FETCH: 'Impossible de récupérer les données',
  ERROR_OCCURED_SEND: "Impossible d'envoyer vos données",
  ERROR_OCCURED_CONTACT: "Impossible d'envoyer votre message",
  GREETINGS: `Pour tester notre parcours client, rendez-vous sur 'Demande de devis', choisissez le département 'Moselle'
  et la localité 'Thionville' où nous avons un expert-comptable de démonstration. (Cliquez pour accéder au site)`,
  SEND_CONTACT:
    "Votre message a bien été envoyé à notre équipe, vous recevrez une réponse d'ici peu",
  NO_EXPERT:
    "Oups ! Aucun expert ne correspond à vos critères. (Cliquez n'importe où pour modifier vos critères)",
};

export default toastMessages;
