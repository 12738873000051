import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CookieContext } from './contexts.js';

const CookieProvider = ({ children }) => {
  const [iconState, setIconState] = useState(false);
  const [iconClicked, setIconClicked] = useState(false);
  const [showBanner, setShowBanner] = useState(false);

  return (
    <CookieContext.Provider
      value={{
        iconState,
        setIconState,
        iconClicked,
        setIconClicked,
        showBanner,
        setShowBanner,
      }}
    >
      {children}
    </CookieContext.Provider>
  );
};

CookieProvider.propTypes = {
  children: PropTypes.node,
};

export default CookieProvider;
