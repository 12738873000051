import React from 'react';
import '../../css/comment.css';
import '../../css/contact.css';
import goldStar from '../../css/images/gold-star.svg';

const Review = () => (
  <div className="back-grey">
    <div className="comment">
      <div className="comment-title">
        <div id="nos-commentAn"></div>
        <h2 id="nos-comment">
          <span className="title-bluesoft">
            Ils ont choisi CEC
            <br />
          </span>{' '}
          et trouvé l'expert-comptable idéal !
        </h2>
      </div>
      <div className="contact-reviews">
        <div className="review-card">
          <div>
            <div className="stars">
              <img className="star" src={goldStar} alt="" />
              <img className="star" src={goldStar} alt="" />
              <img className="star" src={goldStar} alt="" />
              <img className="star" src={goldStar} alt="" />
              <img className="star" src={goldStar} alt="" />
            </div>
            <p>
              Grâce à CEC, j'ai pu trouver un expert-comptable mieux adapté à
              mes besoins, bénéficier d'un conseil accru et réduire mes frais de
              moitié. C'est une excellente nouvelle pour ma gestion financière !
              💼📈
            </p>
            <div className="review-name">
              <div>
                <img src="" alt="" />
                <h3>Smash Nutrition</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="review-card">
          <div>
            <div className="stars">
              <img className="star" src={goldStar} alt="" />
              <img className="star" src={goldStar} alt="" />
              <img className="star" src={goldStar} alt="" />
              <img className="star" src={goldStar} alt="" />
              <img className="star" src={goldStar} alt="" />
            </div>
            <p>
              Grâce à CEC, j'ai trouvé l'expert-comptable idéal pour ma start-up
              en une semaine seulement ! Service ultra rapide et efficace. Je
              recommande vivement !
            </p>
            <div className="review-name">
              <div>
                <img src="" alt="" />
                <h3>GET SPRINGWATER & CO</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Review;
