import React from 'react';
import legalplace from '../../css/images/legalplace.png';
import shine from '../../css/images/shine.png';
import ft from '../../css/images/ft.png';
import agence from '../../css/images/agence.png';
import men from '../../css/images/men-laptop.png';
import '../../css/partners.css';

const Partners = () => (
  <div className="whycec whycec-p">
    <div className="whycec-container">
      <div className="equa">
        <div>
          <img className="img-men img-men-p" src={men} alt="Logo men" />
        </div>
      </div>
      <div className="equa two">
        <div className="titleWhy">
          <h2>
            <span className="title-bluesoft">
              Trouver un expert-comptable
              <br />
            </span>{' '}
            sur CEC
          </h2>
        </div>
        <div className="textwhy">
          <p>
            <span className="text-blue">CEC</span> est un comparateur{' '}
            <span className="text-blue">d'experts-comptables</span> gratuit qui
            vous aide à trouver le{' '}
            <span className="text-blue">comptable idéal</span> pour votre{' '}
            <span className="text-blue">entreprise</span>. En quelques clics,
            comparez les devis de nos{' '}
            <span className="text-blue">experts-comptables partenaires</span> et
            recevez <span className="text-blue">une sélection sur mesure</span>{' '}
            des devis les plus adaptés à vos besoins.
          </p>
        </div>
      </div>
    </div>
    <div className="partners-band">
      <span className="partners-span">Nos partenaires :</span>
      <div className="partners-logos logo-pad">
        <a href="https://lafrenchtechest.fr/startup/cec-choisir-son-expert-70xu/">
          <picture>
            <img className="logo-pool" src={ft} alt="Logo la french tech est" />
          </picture>
        </a>
        <a href="https://www.lagence.expert/newsletter-du-mois-cec">
          <picture>
            <img
              className="logo-agence"
              src={agence}
              alt="Logo l'agence des experts comptables"
            />
          </picture>
        </a>
        <a href="https://www.shine.fr/lp/partenaire/cec/?ae=739&aev=cec">
          <picture>
            <img className="logo-est" src={shine} alt="Logo shine" />
          </picture>
        </a>
        <a href="https://www.legalplace.fr/#ae318&aev15%ou100%E2%82%AC">
          <picture>
            <source srcSet={legalplace} />
            <img
              className="logo-quest"
              src={legalplace}
              alt="Logo LegalPlace"
            />
          </picture>
        </a>
      </div>
    </div>
  </div>
);

export default Partners;
