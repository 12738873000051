import React from 'react';
import PropTypes from 'prop-types';
import '../../css/cookies/cookiesBanner.css';
import cookieIcon from '../../css/images/cookie.svg';

const CookieBanner = ({ onAccept, onDecline }) => (
  <div className="cookie-banner">
    <img src={cookieIcon} alt="Cookie" className="cookie-icon" />
    <p>
      Ce site web utilise des cookies pour améliorer votre expérience
      utilisateur. En continuant à naviguer sur ce site, vous acceptez
      l'utilisation de cookies conformément à notre politique en matière de
      cookies. Pour en savoir plus, veuillez consulter notre{' '}
      <a
        target="_blank"
        rel="noopener"
        href="/cgu"
        className="privacy-policy-link"
      >
        politique de confidentialité
      </a>
      .
    </p>
    <button onClick={onAccept} className="accept-button">
      Accepter cookies
    </button>
    <button onClick={onDecline} className="decline-button">
      Refuser cookies
    </button>
    <span className="close-button" onClick={onDecline}>
      &times;
    </span>
  </div>
);

CookieBanner.propTypes = {
  onAccept: PropTypes.func,
  onDecline: PropTypes.func,
};

export default CookieBanner;
