import React from 'react';
import PropTypes from 'prop-types';
import '../../../../css/articleSections/templateSimple.css';
import blockRenderer from '../blockRenderer.js';

function TemplateSimple({ section }) {
  return (
    <section className="article-template-simple">
      <h2 className="template-simple-title">{section?.Title}</h2>
      <div className="template-simple-content-paragraphs">
        {blockRenderer(section, 'simple')}
      </div>
    </section>
  );
}
export default TemplateSimple;

TemplateSimple.propTypes = {
  section: PropTypes.object,
};
