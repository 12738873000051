import React from 'react';
import { Link } from 'react-router-dom';
import '../../css/footer.css';

const Footer = () => (
  <div className="footer-container">
    <div className="doculegal">
      <Link
        to="/mention"
        target="_blank"
        rel="noreferrer"
        className="footer-link-style"
      >
        Mentions légales
      </Link>
      <Link
        to="/cgu"
        target="_blank"
        rel="noreferrer"
        className="footer-link-style"
      >
        {' '}
        CGU
      </Link>
      <div className="footer-link-style">
        Copyright 2024 CEC ,Tous droits réservés.
      </div>
    </div>
  </div>
);

export default Footer;
