import React from 'react';
import '../../css/who.css';
import idea from '../../css/images/grid2.png';
import add from '../../css/images/grid5.png';
import verified from '../../css/images/grid3.png';
import background1 from '../../css/images/background-4.jpg';

const Who = () => (
  <div className="who-container">
    <div className="background-img">
      <img className="banner" src={background1} alt="" />
    </div>
    <div className="who-box">
      <div className="who-title">
        <div id="who"></div>
        <h2 id="who">
          <span className="title-bluesoft">
            CEC : Expertise-comptable
            <br />
          </span>{' '}
          pour votre réussite
        </h2>
        <p>
          CEC, votre partenaire de confiance pour une gestion comptable optimale
          et sécurisée.
        </p>
      </div>
      <div className="who-grid who-tcase">
        <div className="who-case">
          <div className="whoimg">
            <img className="solution-icons-who" src={idea} alt="" />
          </div>
          <h3 className="subtile-who">
            1. Experts-comptables rigoureusement sélectionnés
          </h3>
          <div>
            <p>
              • Tout nos experts-comptables sont inscrits à l’ordre des
              experts-comptables
            </p>
            <p>• Partenaires référencés pour leurs compétences et engagement</p>
            <p>
              • Service d'excellence pour une comptabilité fiable et sereine
            </p>
            <p>• Vous concentrer sur l'essentiel : votre réussite !</p>
          </div>
        </div>
        <div className="who-case">
          <div className="whoimg">
            <img className="solution-icons-who" src={add} alt="" />
          </div>
          <h3 className="subtile-who">2. Expertise financière solide</h3>
          <div>
            <p>
              • Fondateur diplômé du master de l’expertise comptabilité (DSCG)
            </p>
            <p>• Expérience riche dans le domaine financier</p>
            <p>
              • Service d'excellence pour une comptabilité fiable et sereine
            </p>
            <p>• Gestion experte de vos besoins comptables et financiers</p>
          </div>
        </div>
        <div className="who-case">
          <div className="whoimg">
            <img className="solution-icons-who" src={verified} alt="" />
          </div>
          <h3 className="subtile-who">3. Sécurité informatique de pointe</h3>
          <div>
            <p>
              • Co-fondateur deux ingénieurs informatique (dont un en
              cybersécurité)
            </p>
            <p>• Protection et confidentialité de vos données garanties</p>
            <p>• Expérience utilisateur sécurisée</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Who;
