import React from 'react';
import '../../css/solution.css';
import idea from '../../css/images/idea.svg';
import hourglass from '../../css/images/hourglass.svg';
import add from '../../css/images/add.svg';
import verified from '../../css/images/verified.svg';

const Solution = () => (
  <div className="solution-container-w">
    <div className="solution-title">
      <div id="nos-solutionsAn"></div>
      <h2 id="nos-solutions">
        Pourquoi utiliser{' '}
        <span className="title-bluesoft">
          CEC ?
          <br />
        </span>
      </h2>
      <p>
        Le comparateur d'experts CEC a été conçu pour simplifier vos démarches
        dans la recherche d'un expert-comptable répondant à vos besoins. Notre
        comparateur se focalise sur quatre grands points, assurant ainsi une
        expérience optimale.
      </p>
    </div>
    <div className="solution-grid">
      <div className="solution-card-w tred">
        <div className="icons">
          <img className="solution-icons-small" src={idea} alt="" />
        </div>
        <div className="solution-subtitle-r">
          <h3>Solution sur mesure</h3>
          <p>
            Nous comprenons vos besoins et vous proposons des solutions
            personnalisées pour votre entreprise.
          </p>
        </div>
      </div>
      <div className="solution-card-w tblue">
        <div className="icons">
          <img className="solution-icons-small" src={hourglass} alt="" />
        </div>
        <div className="solution-subtitle-b">
          <h3>Gain de temps</h3>
          <p>
            Gagnez du temps en utilisant notre comparateur pour trouver
            l'expert-comptable idéal en quelques minutes.
          </p>
        </div>
      </div>
      <div className="solution-card-w tgreen">
        <div className="icons">
          <img className="solution-icons-small" src={add} alt="" />
        </div>
        <div className="solution-subtitle-g">
          <h3>Sélection d&apos;experts</h3>
          <p>
            Nous sélectionnons les 3 experts-comptables les plus qualifiés pour
            répondre à vos besoins.
          </p>
        </div>
      </div>
      <div className="solution-card-w tyellow">
        <div className="icons">
          <img className="solution-icons-small" src={verified} alt="" />
        </div>
        <div className="solution-subtitle-y">
          <h3>Rapide & Gratuit</h3>
          <p>
            Remplissez notre formulaire en 3 à 5 minutes et recevez
            <br /> 3 devis d'experts-comptables sur mesure.
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default Solution;
