import React from 'react';
import Header from './Header.js';
import Contact from './Contact.js';
import Footer from './Footer.js';
import Footerextented from './Footerextented.js';

import { motion } from 'framer-motion';
import SEO from '../SEO/seo.js';

const Homepage = () => (
  <motion.div>
    <SEO />
    <Header />
    <Contact />
    <Footerextented />
    <Footer />
  </motion.div>
);

export default Homepage;
