export const countriesOptions = [
  {
    value: 'France',
    label: 'France',
  },
  {
    value: 'Luxembourg',
    label: 'Luxembourg',
  },
];
