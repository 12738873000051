import React, { forwardRef, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import model from '../model/formData.js';

const Step0 = forwardRef((props, ref) => {
  const [isOnCreation, setIsOnCreation] = useState(props.model?.isCreation);

  useImperativeHandle(ref, () => ({
    validateStep,
  }));

  const validateStep = () => {
    if (!isOnCreation) {
      const defaults = model.setDefaults(props.model);

      defaults.isCreation = isOnCreation;

      return defaults;
    }

    return model.setDefaults(props.model);
  };

  return (
    <>
      <span className="devis-form-span">
        Votre entreprise est-elle en création ou date de moins d'un an ?
      </span>
      <div className="devis-radio-btn devis-wrap-container">
        <div>
          <input
            type="radio"
            id="new-one"
            value="new-one"
            name="on-creation"
            onChange={() => setIsOnCreation(true)}
            checked={isOnCreation === true}
          />
          <label htmlFor="new-one" className="devis-form-label-radio">
            Oui
          </label>
        </div>
        <div>
          <input
            type="radio"
            id="old-one"
            value="old-one"
            name="on-creation"
            onChange={() => setIsOnCreation(false)}
            checked={isOnCreation === false}
          />
          <label htmlFor="old-one" className="devis-form-label-radio">
            Non
          </label>
        </div>
      </div>
    </>
  );
});

Step0.propTypes = {
  model: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

Step0.displayName = 'Step0';

export default Step0;
