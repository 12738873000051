import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

function AnimatedStep({ classes, id, children, currentStep }) {
  const rootClasses = `form-step ${classes}`;

  useEffect(() => {
    resizeStep(0);
  }, [currentStep]);

  const resizeStep = (additionalSize = 0) => {
    const elm = document.getElementById(id);

    if (elm === null) return;

    if (id === `form-step-${currentStep}`) {
      setTimeout(() => {
        elm.style.maxHeight = `${elm.scrollHeight + 45 + additionalSize}px`;
      }, 100);

      setTimeout(() => {
        elm.style.maxHeight = `${elm.scrollHeight + 45 + additionalSize}px`;
      }, 1000);
    } else {
      setTimeout(() => {
        elm.removeAttribute('style');
      }, 120);
    }
  };

  const updateSizeOnDropdown = (open = true) => {
    if (open) resizeStep(300);
    else resizeStep(0);
  };

  return (
    <div className={rootClasses} id={id}>
      {React.cloneElement(children, {
        updateSizeOnDropdown,
      })}
    </div>
  );
}

AnimatedStep.propTypes = {
  classes: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  currentStep: PropTypes.number.isRequired,
};

export default AnimatedStep;
