import themes from './toastThemes.js';
import toastMessages from './toastMessages.js';
import toastHeaders from './toastHeaders.js';

const defaultToastOptions = {
  duration: 5000,
  theme: 'info',
  zIndex: 1000,
  className: '',
  clickable: false,
  clickClosable: false,
  position: 'bottom-left',
  maxVisibleToasts: 3,
  render: null,
  isReversedOrder: false,
  onClick: () => {},
  onClose: () => {},
  onCloseStart: () => {},
};

const toastPositions = {
  TOP_LEFT: 'top-left',
  TOP_CENTER: 'top-center',
  TOP_RIGHT: 'top-right',
  CENTER: 'center',
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_CENTER: 'bottom-center',
  BOTTOM_RIGHT: 'bottom-right',
};

function verifyPosition(position) {
  return Object.values(ToastPositions).includes(position)
    ? position
    : defaultToastOptions.position;
}

export const DefaultToastOptions = defaultToastOptions;
export const ToastPositions = toastPositions;
export const ToastThemes = themes;
export const ToastMessages = toastMessages;
export const ToastHeaders = toastHeaders;

const ToastsOptions = {
  DefaultToastOptions,
  ToastPositions,
  ToastThemes,
  ToastMessages,
  ToastHeaders,
  verifyPosition,
};

export default ToastsOptions;
