import React from 'react';
import PropTypes from 'prop-types';
import '../../../../css/articleSections/templateColumns.css';
import blockRenderer from '../blockRenderer.js';

function TemplateColumns({ section }) {
  return (
    <section className="article-template-columns">
      <h2 className="template-columns-title">{section?.Title}</h2>
      <div className="template-columns-row">
        <div className="template-columns-left">
          {blockRenderer({ Paragraphs: section?.Left_paragraphs }, 'columns')}
        </div>
        <div className="template-columns-right">
          {blockRenderer({ Paragraphs: section?.Right_paragraphs }, 'columns')}
        </div>
      </div>
    </section>
  );
}
export default TemplateColumns;

TemplateColumns.propTypes = {
  section: PropTypes.object,
};
