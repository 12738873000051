import React from 'react';
import '../../css/mobile.css';
import { useLocation, Routes, Route } from 'react-router-dom';
import Devis from '../Forms/Form/Devis.js';
import Contact from './Contactpage.js';
import Homepage from './Homepage.js';
import { AnimatePresence } from 'framer-motion';
import Mention from './mention.js';
import Cgu from './cgu.js';
// import Thanks from './Thanks.js';
// import Quotes from './Quotes.js';
import Error404 from './404.js';
import Articles from './Articles/Articles.js';
import Article from './Articles/Article.js';
import ContactExpert from './ContactpageExpert.js';
import Who from './Whopage.js';

function AnimatedRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/devis" element={<Devis pageName="devis" />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/contact-expert" element={<ContactExpert />} />
        <Route path="/qui-sommes-nous" element={<Who />} />
        <Route path="/mention" element={<Mention />} />
        <Route path="/cgu" element={<Cgu />} />
        {/* <Route path="/thanks" element={<Thanks />} />
        <Route path="/quotes" element={<Quotes pageName="quotes" />} /> */}
        <Route path="/" element={<Homepage pageName="homepage" />} />
        <Route path="/articles" element={<Articles pageName="articles" />} />
        <Route path="/articles/:id" element={<Article pageName="articles" />} />
        <Route path="*" element={<Error404 pageName="404" />} />
      </Routes>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;
