import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ClientContext } from './contexts.js';
import { shouldFetchData, status } from '../../static/data/fetchStatus.js';
import quoteService from '../Quotes/quote.service.js';
import useToast from '../Toasts/useToast.js';
import {
  ToastHeaders,
  ToastThemes,
  ToastMessages,
} from '../Toasts/ToastOptions/toastOptions.js';

function ClientProvider({ children }) {
  const enqueueToast = useToast();
  const [clientId, setClientId] = useState(undefined);
  const [quotes, setQuotes] = useState([]);
  const [quotesFetched, setQuotesFetched] = useState(status.DEFAULT);

  // Should check if any data is in fetching state
  // Status : waiting, loading, finished
  const [fetchingCurrentState, setFetchingCurrentState] = useState({
    quotes: 'waiting',
    cers: 'waiting',
  });

  const updateFetchingStatus = (key, value) => {
    setFetchingCurrentState((fetchingCurrentState) => ({
      ...fetchingCurrentState,
      ...{ [key]: value },
    }));
  };

  const fetchQuotes = useCallback(async () => {
    if (clientId === undefined || quotesFetched === status.FETCHED) return;

    setQuotesFetched(status.FETCHED);
    updateFetchingStatus('quotes', 'loading');

    try {
      const data = await quoteService.quotesByClientID(clientId);

      if (data.length === 0) {
        updateFetchingStatus('quotes', 'finished');

        return;
      }

      setQuotes(data);
    } catch (error) {
      console.log(clientId, quotesFetched, enqueueToast);
      console.error(error);
      enqueueToast(ToastMessages.ERROR_OCCURED_FETCH, {
        theme: ToastThemes.toastThemes.ERROR_OCCURED,
        header: ToastHeaders[ToastThemes.toastThemes.ERROR_OCCURED],
        duration: 3000,
      });
    }

    updateFetchingStatus('quotes', 'finished');
  }, [clientId, quotesFetched, enqueueToast]);

  useEffect(() => {
    if (clientId) {
      if (shouldFetchData(quotesFetched)) fetchQuotes();
    }
  }, [clientId, quotesFetched, fetchQuotes]);

  return (
    <ClientContext.Provider
      value={{
        clientId,
        setClientId,
        quotes,
        fetchingCurrentState,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
}

ClientProvider.propTypes = {
  children: PropTypes.node,
};

export default ClientProvider;
