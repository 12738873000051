import React from 'react';
import PropTypes from 'prop-types';
import '../../../../css/articleSections/templateCenter.css';
import blockRenderer from '../blockRenderer.js';

function TemplateCenter({ section }) {
  return (
    <section className="article-template-center">
      <h2 className="template-center-title">{section?.Title}</h2>
      <div className="template-center-content"></div>
      {console.log(section)}
      {blockRenderer(section, 'center')}
      <div className="template-center-buttons">
        {
          <button type="button" className="template-center-btn-left">
            <a
              href={section?.Left_button_action}
              className="template-center-btn-left-link"
            >
              {section?.Left_button_content}
            </a>
          </button>
        }
        {
          <button type="button" className="template-center-btn-right">
            <a
              href={section?.Right_button_action}
              className="template-center-btn-right-link"
              target="_blank"
              rel="noreferrer"
              download="cec-guide"
            >
              {section?.Right_button_content}
            </a>
          </button>
        }
      </div>
    </section>
  );
}
export default TemplateCenter;

TemplateCenter.propTypes = {
  section: PropTypes.object,
  props: PropTypes.any,
  format: PropTypes.any,
  children: PropTypes.any,
};
