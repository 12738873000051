import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import '../../../../css/articleSections/templateImg.css';
import blockRenderer from '../blockRenderer.js';

const defaultStrapiGetUrl = `${process.env.REACT_APP_STRAPI_API_URL}images/`;

function TemplateImg({ section }) {
  const [image, setImage] = useState({});

  const getImage = async () => {
    const { data } = await axios.get(
      `${defaultStrapiGetUrl}${section?.Image_id}?populate=*`,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_GET_TOKEN}`,
        },
      }
    );

    setImage(data?.data || {});
  };

  useEffect(() => {
    try {
      getImage();
    } catch (e) {
      console.error(e);
    }
  }, []);

  const getImageInfo = () => {
    if (Object.keys(image).length === 0) return '';

    const imgAttr = image?.attributes?.Image?.data?.attributes;

    if (imgAttr === undefined) return;

    if (imgAttr?.provider === 'local')
      return {
        src: `http://localhost:1337${imgAttr?.url}`,
        alt: imgAttr?.alternativeText,
        width: imgAttr?.width,
        height: imgAttr?.height,
      };

    return {
      src: imgAttr?.url,
      alt: imgAttr?.alternativeText,
      width: imgAttr?.width,
      height: imgAttr?.height,
    };
  };

  const getSectionClasses = (classes) => {
    if (section?.Image_on_left)
      return `${classes} article-template-img-reverse`;

    return classes;
  };

  const imageInfos = getImageInfo();

  return (
    <section
      className={getSectionClasses('article-template-img')}
      key={`section-${section?.Title}`}
    >
      <div className="template-img-content-column">
        <h2 className="template-img-title">{section?.Title}</h2>
        <div className="template-img-content-paragraphs">
          {blockRenderer(section, 'img')}
        </div>
        {section?.Show_button && (
          <button type="button" className="template-img-button">
            <a
              href={section?.Button_action || '#'}
              className="template-img-button-link"
            >
              {section?.Button_content}
            </a>
          </button>
        )}
      </div>
      <div className="template-img-image-column">
        {imageInfos !== undefined && (
          <picture className="template-img-picture">
            <source
              srcSet={imageInfos?.src}
              // style={{
              //   maxWidth: `${imageInfos?.width}px`,
              //   maxHeight: `${imageInfos?.height}px`,
              // }}
            />
            <img
              src={imageInfos?.src}
              alt={imageInfos?.alt}
              className="template-img-image"
              // style={{
              //   maxWidth: `${imageInfos?.width}px`,
              //   maxHeight: `${imageInfos?.height}px`,
              // }}
            />
          </picture>
        )}
      </div>
    </section>
  );
}
export default TemplateImg;

TemplateImg.propTypes = {
  section: PropTypes.object,
  // id: PropTypes.number,
  // Order: PropTypes.number,
  // Title: PropTypes.string,
  // Button_content: PropTypes.string,
  // Show_button: PropTypes.bool,
  // Image_on_left: PropTypes.bool,
  // Template_type: PropTypes.string,
  // Paragraphs: PropTypes.array,
};
