import themes from './toastThemes.js';

const toastHeaders = {
  [themes.toastThemes.CONFIRM_SUCCESS]: 'Merci pour votre confirmation',
  [themes.toastThemes.SEND_CONTACT]:
    "Merci d'avoir pris contact avec notre équipe",
  [themes.toastThemes.ERROR_OCCURED]: 'Une erreur est survenue',
  [themes.toastThemes.NO_EXPERT]:
    'Oups ! Aucun expert ne correspond à vos critères.',
  [themes.toastThemes.GREETINGS]:
    'CEC en avant-première : Préparez-vous pour le référencement imminent des experts comptables dans toute la France !',
};

export default toastHeaders;
