import React from 'react';
import { Fade } from 'react-awesome-reveal';
import SEO from '../SEO/seo.js';

const Cgu = () => (
  <>
    <SEO
      pageType="website"
      pageTitle="Choisir Son Expert Comptable - CEC - CGU"
      pageDescription="Conditions générales d'utilisation de la plateforme CEC - Choisir son Expert-Comptable."
      pageURL="https://www.choisir-son-expert-comptable.fr/cgu"
    />
    <Fade duration={2000}>
      <div className="cgu container">
        <h1>
          Conditions générales d&apos;utilisation du site CEC Choisir son
          expert-comptable
        </h1>

        <h2>Préambule</h2>

        <div className="Pdfpara">
          <ul>
            Les présentes conditions générales d&apos;utilisation sont conclues
            entre :
            <li>le gérant du site internet, ci-après désigné « l’Éditeur »,</li>
            <li>
              {''}
              toute personne souhaitant accéder au site et à ses services,
              ci-après appelé « l’Utilisateur ».
            </li>
          </ul>
        </div>

        <h2>Article 1 - Principes</h2>

        <div className="Pdfpara">
          <p>
            Les présentes conditions générales d&apos;utilisation ont pour objet
            l&apos;encadrement juridique de l’utilisation du site CEC Choisir
            son expert comptable et de ses services.
          </p>

          <ul>
            Le site Internet www.choisir-son-expert-comptable.fr est un service
            de :<li> La société CEC choisir son expert comptable.</li>
            <li>
              {''}
              adresse URL du site : www.choisir-son-expert-comptable.fr
            </li>
            <li> e-mail : cec@choisir-son-expert-comptable.fr</li>
          </ul>
        </div>

        <div className="Pdfpara">
          <p>
            Les conditions générales d&apos;utilisation doivent être acceptées
            par tout Utilisateur, et son accès au site vaut acceptation de ces
            conditions.
          </p>
        </div>

        <h2>Article 2 - Evolution et durée des CGU</h2>

        <div className="Pdfpara">
          <p>
            Les présentes conditions générales d&apos;utilisation sont conclues
            pour une durée indéterminée. Le contrat produit ses effets à
            l&apos;égard de l&apos;Utilisateur à compter du début de
            l’utilisation du service. Le site CEC Choisir son expert-comptable
            se réserve le droit de modifier les clauses de ces conditions
            générales d’utilisation à tout moment et sans justification.
          </p>
        </div>

        <h2>Article 3 - Accès au site</h2>

        <div className="Pdfpara">
          <p>
            Tout Utilisateur ayant accès à internet peut accéder gratuitement et
            depuis n’importe où au site CEC Choisir son expert-comptable. Les
            frais supportés par l’Utilisateur pour y accéder (connexion
            internet, matériel informatique, etc.) ne sont pas à la charge de
            l’Éditeur. Le site et ses différents services peuvent être
            interrompus ou suspendus par l’Éditeur, notamment à l’occasion d’une
            maintenance, sans obligation de préavis ou de justification.
            L&apos;Utilisateur du site a accès aux services suivants : mise en
            relation des entreprises aux experts comptables. Le site comprend un
            espace membre payant réservé aux utilisateurs inscrits. Ces
            utilisateurs pourront y accéder en utilisant leurs identifiants de
            connexion. Les services réservés aux membres sont les suivants :
            Mise en relation avec un expert-comptable ou avec un futur client.
          </p>
        </div>

        <h2>Article 4 - Responsabilités</h2>

        <div className="Pdfpara">
          <p>
            La responsabilité de l&apos;Éditeur ne peut être engagée en cas de
            défaillance, panne, difficulté ou interruption de fonctionnement,
            empêchant l&apos;accès au site ou à une de ses fonctionnalités. Le
            matériel de connexion au site utilisée est sous l&apos;entière
            responsabilité de l&apos;Utilisateur qui doit prendre toutes les
            mesures appropriées pour protéger le matériel et les données
            notamment d&apos;attaques virales par Internet. L&apos;utilisateur
            est par ailleurs le seul responsable des sites et données qu&apos;il
            consulte.
          </p>

          <ul>
            L&apos;Éditeur ne pourra être tenu responsable en cas de poursuites
            judiciaires à l&apos;encontre de l&apos;Utilisateur :
            <li>
              {''}
              du fait de l&apos;usage du site ou de tout service accessible via
              Internet ;
            </li>
            <li>
              {''}
              du fait du non-respect par l&apos;Utilisateur des présentes
              conditions générales.
            </li>
          </ul>
          <p>
            L&apos;Éditeur n&apos;est pas responsable des dommages causés à
            l&apos;Utilisateur, à des tiers et/ou à l&apos;équipement de
            l&apos;Utilisateur du fait de sa connexion ou de son utilisation du
            site et l&apos;Utilisateur renonce à toute action contre
            l&apos;Éditeur de ce fait. Si l&apos;Éditeur venait à faire
            l&apos;objet d&apos;une procédure amiable ou judiciaire à raison de
            l’utilisation du site par l&apos;Utilisateur, il pourrait retourner
            contre lui pour obtenir indemnisation de tous les préjudices,
            sommes, condamnations et frais qui pourraient découler de cette
            procédure.
          </p>

          <ul>
            Il est permis à l&apos;Utilisateur de publier sur le site CEC
            Choisir son expert-comptable :<li>des commentaires</li>
          </ul>
          <p>
            L&apos;Utilisateur s’engage à tenir des propos respectueux des
            autres et de la loi et accepte que ces publications soient modérées
            ou refusées par l’Éditeur, sans obligation de justification. En
            publiant sur le site, l’Utilisateur cède à la société éditrice le
            droit non exclusif et gratuit de représenter, reproduire, adapter,
            modifier, diffuser et distribuer sa publication, directement ou par
            un tiers autorisé. L’Éditeur s&apos;engage toutefois à citer le
            membre en cas d’utilisation de sa publication.
          </p>
        </div>

        <h2>Article 5 - Propriété intellectuelle</h2>

        <div className="Pdfpara">
          <p>
            Tous les documents techniques, produits, photographies, textes,
            logos, dessins, vidéos, etc., sont soumis à des droits d&apos;auteur
            et sont protégés par le Code de la propriété intellectuelle.
            Lorsqu&apos;ils sont remis à nos clients, ils demeurent la propriété
            exclusive de CEC choisir son expert-comptable., seul titulaire des
            droits de propriété intellectuelle sur ces documents, qui doivent
            lui être rendus à sa demande. Nos clients s&apos;engagent à ne faire
            aucun usage de ces documents, susceptible de porter atteinte aux
            droits de propriété industrielle ou intellectuelle du fournisseur et
            s&apos;engagent à ne les divulguer à aucun tiers, en dehors
            d&apos;une autorisation expresse et préalable donnée par
            l&apos;Editeur.
          </p>
        </div>

        <h2>Article 6 - Liens hypertextes</h2>

        <div className="Pdfpara">
          <p>
            La mise en place par l&apos;Utilisateur de tous liens hypertextes
            vers tout ou partie du site est strictement interdite, sauf
            autorisation préalable et écrite de l&apos;Éditeur, sollicitée par
            courriel à l&apos;adresse suivante :
            cec@choisir-son-expert-comptable.fr. L&apos;Éditeur est libre de
            refuser cette autorisation sans avoir à justifier de quelque manière
            que ce soit sa décision. Dans le cas où l&apos;Éditeur accorderait
            son autorisation, celle-ci n&apos;est dans tous les cas que
            temporaire et pourra être retirée à tout moment, sans obligation de
            justification à la charge de l&apos;Éditeur. Dans tous les cas, tout
            lien devra être retiré sur simple demande de l&apos;Éditeur. Toute
            information accessible via un lien vers d&apos;autres sites
            n&apos;est pas sous le contrôle de l&apos;Éditeur qui décline toute
            responsabilité quant à leur contenu.
          </p>
        </div>

        <h2>Article 7 - Protection des données personnelles</h2>

        <h3>Données collectées</h3>

        <div className="Pdfpara">
          <ul>
            Les données à caractère personnel qui sont collectées sur ce site
            sont les suivantes :
            <li>
              ouverture de compte : lors de la création du compte de
              l&apos;utilisateur : nom ; prénom ; adresse électronique ; n° de
              téléphone ; Données financières sur l’entreprise ;
            </li>
            <li>
              connexion : lors de la connexion de l&apos;utilisateur au site
              web, celui-ci enregistre, notamment, ses nom, prénom, données de
              connexion, d&apos;utilisation, de localisation et ses données
              relatives au paiement ;
            </li>
            <li>
              profil : l&apos;utilisation des prestations prévues sur le site
              web permet de renseigner un profil, pouvant comprendre une adresse
              et un numéro de téléphone ;
            </li>
            <li>
              paiement : dans le cadre du paiement des produits et prestations
              proposés sur le site web, celui-ci enregistre des données
              financières relatives au compte bancaire ou à la carte de crédit
              de l&apos;utilisateur ;
            </li>
            <li>
              communication : lorsque le site web est utilisé pour communiquer
              avec d&apos;autres membres, les données concernant les
              communications de l&apos;utilisateur font l&apos;objet d&apos;une
              conservation temporaire ;
            </li>
            <li>
              cookies : les cookies sont utilisés, dans le cadre de
              l&apos;utilisation du site. L&apos;utilisateur a la possibilité de
              désactiver les cookies à partir des paramètres de son navigateur.
            </li>
          </ul>
        </div>

        <h3>Utilisation des données personnelles</h3>

        <div className="Pdfpara">
          <ul>
            Les données personnelles collectées auprès des utilisateurs ont pour
            objectif la mise à disposition des services du site web, leur
            amélioration et le maintien d&apos;un environnement sécurisé. Plus
            précisément, les utilisations sont les suivantes :
            <li>accès et utilisation du site web par l&apos;utilisateur ;</li>
            <li>gestion du fonctionnement et optimisation du site web ;</li>
            <li>
              organisation des conditions d&apos;utilisation des Services de
              paiement ;
            </li>
            <li>
              vérification, identification et authentification des données
              transmises par l&apos;utilisateur ;
            </li>
            <li>
              proposition à l&apos;utilisateur de la possibilité de communiquer
              avec d&apos;autres utilisateurs du site web ;
            </li>
            <li>mise en oeuvre d&apos;une assistance utilisateurs ;</li>
            <li>
              personnalisation des services en affichant des publicités en
              fonction de l&apos;historique de navigation de l&apos;utilisateur,
              selon ses préférences ;
            </li>
            <li>
              prévention et détection des fraudes, malwares (malicious softwares
              ou logiciels malveillants) et gestion des incidents de sécurité ;
            </li>
            <li>gestion des éventuels litiges avec les utilisateurs ;</li>
            <li>
              envoi d&apos;informations commerciales et publicitaires, en
              fonction des préférences de l&apos;utilisateur.
            </li>
          </ul>
        </div>

        <h3>Partage des données personnelles avec des tiers</h3>

        <div className="Pdfpara">
          <ul>
            Les données personnelles peuvent être partagées avec des sociétés
            tierces, dans les cas suivants :
            <li>
              lorsque l&apos;Utilisateur utilise les services de paiement, pour
              la mise en oeuvre de ces services, le site web est en relation
              avec des sociétés bancaires et financières tierces avec lesquelles
              elle a passé des contrats ;
            </li>
            <li>
              lorsque l&apos;Utilisateur publie, dans les zones de commentaires
              libres du site web, des informations accessibles au public ;
            </li>
            <li>
              lorsque l&apos;Utilisateur autorise le site web d&apos;un tiers à
              accéder à ses données ;
            </li>
            <li>
              lorsque le site web recourt aux services de prestataires pour
              fournir l&apos;assistance utilisateurs, la publicité et les
              services de paiement. Ces prestataires disposent d&apos;un accès
              limité aux données de l&apos;utilisateur, dans le cadre de
              l&apos;exécution de ces prestations, et ont une obligation
              contractuelle de les utiliser en conformité avec les dispositions
              de la réglementation applicable en matière protection des données
              à caractère personnel ;
            </li>
            <li>
              si la loi l&apos;exige, le site web peut effectuer la transmission
              de données pour donner suite aux réclamations présentées contre le
              site web et se conformer aux procédures administratives et
              judiciaires ;
            </li>
            <li>
              si le site web est impliquée dans une opération de fusion,
              acquisition, cession d&apos;actifs ou procédure de redressement
              judiciaire, elle pourra être amenée à céder ou partager tout ou
              partie de ses actifs, y compris les données à caractère personnel.
              Dans ce cas, les utilisateurs seraient informés, avant que les
              données à caractère personnel ne soient transférées à une tierce
              partie.
            </li>
          </ul>
        </div>

        <h3>Sécurité et confidentialité</h3>

        <div className="Pdfpara">
          <p>
            Le site web met en œuvre des mesures organisationnelles, techniques,
            logicielles et physiques en matière de sécurité du numérique pour
            protéger les données personnelles contre les altérations,
            destructions et accès non autorisés. Toutefois, il est à signaler
            qu&apos;internet n&apos;est pas un environnement complètement
            sécurisé et le site web ne peut pas garantir la sécurité de la
            transmission ou du stockage des informations sur internet.
          </p>

          <h3>Mise en œuvre des droits des utilisateurs</h3>

          <ul>
            En application de la réglementation applicable aux données à
            caractère personnel, les utilisateurs disposent des droits suivants,
            qu&apos;ils peuvent exercer en faisant leur demande à l&apos;adresse
            suivante : cec@choisir-son-expert-comptable.fr.
            <li>
              {''}
              le droit d’accès : ils peuvent exercer leur droit d&apos;accès,
              pour connaître les données personnelles les concernant. Dans ce
              cas, avant la mise en œuvre de ce droit, le site web peut demander
              une preuve de l&apos;identité de l&apos;utilisateur afin d&apos;en
              vérifier l&apos;exactitude.
            </li>
            <li>
              {''}
              le droit de rectification : si les données à caractère personnel
              détenues par le site web sont inexactes, ils peuvent demander la
              mise à jour des informations.
            </li>
            <li>
              {''}
              le droit de suppression des données : les utilisateurs peuvent
              demander la suppression de leurs données à caractère personnel,
              conformément aux lois applicables en matière de protection des
              données.
            </li>
            <li>
              {''}
              le droit à la limitation du traitement : les utilisateurs peuvent
              de demander au site web de limiter le traitement des données
              personnelles conformément aux hypothèses prévues par le RGPD.
            </li>
            <li>
              {''}
              le droit de s’opposer au traitement des données : les utilisateurs
              peuvent s’opposer à ce que ses données soient traitées
              conformément aux hypothèses prévues par le RGPD.
            </li>
            <li>
              {''}
              le droit à la portabilité : ils peuvent réclamer que le site web
              leur remette les données personnelles qui lui sont fournies pour
              les transmettre à un nouveau site web. Evolution de la présente
              clause Le site web se réserve le droit d&apos;apporter toute
              modification à la présente clause relative à la protection des
              données à caractère personnel à tout moment. Si une modification
              est apportée à la présente clause de protection des données à
              caractère personnel, le site web s&apos;engage à publier la
              nouvelle version sur son site. Le site web informera également les
              utilisateurs de la modification par messagerie électronique, dans
              un délai minimum de 15 jours avant la date d&apos;effet. Si
              l&apos;utilisateur n&apos;est pas d&apos;accord avec les termes de
              la nouvelle rédaction de la clause de protection des données à
              caractère personnel, il a la possibilité de supprimer son compte.
            </li>
          </ul>
        </div>

        <h2>Article 8 - Cookies</h2>

        <div className="Pdfpara">
          <p>
            Le site CEC Choisir son expert-comptable peut collecter
            automatiquement des informations standards. Toutes les informations
            collectées indirectement ne seront utilisées que pour suivre le
            volume, le type et la configuration du trafic utilisant ce site,
            pour en développer la conception et l&apos;agencement et à
            d&apos;autres fins administratives et de planification et plus
            généralement pour améliorer le service que nous vous offrons.
          </p>
        </div>

        <h2>Article 9 - Loi applicable</h2>

        <div className="Pdfpara">
          <p>
            Les présentes conditions générales d&apos;utilisation sont soumises
            à l&apos;application du droit français. Si les parties
            n&apos;arrivent pas à résoudre un litige à l&apos;amiable, le litige
            sera soumis à la compétence des tribunaux français.
          </p>
        </div>
      </div>
    </Fade>
  </>
);

export default Cgu;
