import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import '../../css/cookies/cookieToggler.css';
import cookieIconClicked from '../../css/images/cookieIconClicked.svg';
import cookieIcon from '../../css/images/cookieIcon.svg';
import { CookieContext } from '../Contexts/contexts.js';

function CookieToggler({ toggleBanner, isClicked }) {
  const { iconState, setIconState } = useContext(CookieContext);

  const getIconSource = () => {
    if (iconState) return cookieIconClicked;

    return cookieIcon;
  };

  return (
    <div className="cookie-icon-container" onClick={toggleBanner}>
      <img
        src={getIconSource()}
        className="cookie-icon"
        alt="Cookie Banner Toggle"
        onMouseEnter={() => setIconState(true)}
        onMouseLeave={() => {
          if (!isClicked) {
            setIconState(false);
          }
        }}
        onClick={() => {
          if (!isClicked) {
            setIconState(true);
          } else {
            setIconState(false);
          }
        }}
      />
    </div>
  );
}

CookieToggler.propTypes = {
  toggleBanner: PropTypes.func,
  isClicked: PropTypes.bool,
};

export default CookieToggler;
