import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import '../../../../css/articleSections/templateRelated.css';
import { dayMonthYearFormat } from '../../../../utils/date.js';
import { Link } from 'react-router-dom';

const defaultStrapiGetUrl = `${process.env.REACT_APP_STRAPI_API_URL}articles?populate[0]=Thumbnail&`;

function TemplateRelated({ articles }) {
  const [relatedArticles, setRelatedArticles] = useState([]);

  const getRelatedArticles = async () => {
    try {
      // eslint-disable-next-line
      const idList = articles.map(({ Article_id }) => Article_id);
      let query = '';

      idList.forEach((id, idx) => {
        if (query.length === 0) query += `filters[id][$in][${idx}]=${id}`;
        else query += `&filters[id][$in][${idx}]=${id}`;
      });

      const { data } = await axios.get(`${defaultStrapiGetUrl}${query}`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_GET_TOKEN}`,
        },
      });

      setRelatedArticles(data?.data || []);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getRelatedArticles();
  }, []);

  return (
    <section className="article-template-related">
      <h3 className="template-related-title">
        Ces articles pourraient vous intéresser
      </h3>
      <div className="template-related-container">
        {relatedArticles.length > 0 &&
          relatedArticles.map((relatedArticle, index) => {
            const thumbnail =
              relatedArticle?.attributes?.Thumbnail?.data?.attributes;
            if (index < 5)
              return (
                <Link
                  to={`/articles/${relatedArticle?.attributes?.Slug}`}
                  className="template-related-card"
                  key={`related-article-${index}`}
                >
                  <picture className="template-related-picture">
                    <source
                      srcSet={
                        thumbnail?.provider === 'local'
                          ? `http://localhost:1337${thumbnail?.url}`
                          : thumbnail?.url
                      }
                    />
                    <img
                      src={
                        thumbnail?.provider === 'local'
                          ? `http://localhost:1337${thumbnail?.url}`
                          : thumbnail?.url
                      }
                      className="related-card-img"
                      alt={thumbnail?.alternativeText}
                    />
                  </picture>

                  <div className="related-card-container">
                    <span className="related-card-date">
                      {dayMonthYearFormat(
                        relatedArticle?.attributes?.Publication_date
                      )}
                    </span>
                    <h4 className="related-card-title">
                      {relatedArticle?.attributes?.Title}
                    </h4>
                  </div>
                </Link>
              );

            return <></>;
          })}
      </div>
    </section>
  );
}
export default TemplateRelated;

TemplateRelated.propTypes = {
  articles: PropTypes.array,
};
