/* eslint-disable camelcase */
import React, { useContext, useEffect } from 'react';
import CookieToggler from './cookieToggler.js';
import CookieBanner from './cookieBanner.js';
import ReactGA from 'react-ga4';
import Cookies from 'js-cookie';
import { CookieContext } from '../Contexts/contexts.js';

const ga4 = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

const deleteGACookies = () => {
  // Récupérer tous les cookies
  const allCookies = Cookies.get();

  // Parcourir chaque cookie
  for (const cookieName in allCookies) {
    if (
      cookieName.startsWith('_ga') ||
      cookieName.startsWith('_gid') ||
      cookieName.startsWith('_gat')
    ) {
      // Supprimer le cookie
      Cookies.remove(cookieName);
    }
  }
};

function CookieBannerWrapper() {
  const { showBanner, setShowBanner, setIconState } = useContext(CookieContext);

  useEffect(() => {
    if (!Cookies.get('cookie-consent-given')) {
      setShowBanner(true);
      setIconState(true);
    }
  }, []);

  const toggleBanner = () => {
    setShowBanner(!showBanner);
  };

  const handleAccept = async () => {
    try {
      ReactGA.initialize(ga4, {
        gtagOptions: {
          cookie_update: false,
          cookie_expires: 180 * 24 * 60 * 60,
        },
      });
      Cookies.set('cookie-consent-given', 'true', { expires: 180 });
      setShowBanner(false);
    } catch (error) {
      console.error('Failed to initialize GA4', error);
    }
    setIconState(false);
  };

  const handleDecline = () => {
    deleteGACookies();
    Cookies.set('cookie-consent-given', 'true', { expires: 30 });
    setShowBanner(false);
    setIconState(false);
  };

  return (
    <>
      <CookieToggler toggleBanner={toggleBanner} isClicked={showBanner} />
      {showBanner && (
        <CookieBanner onAccept={handleAccept} onDecline={handleDecline} />
      )}
    </>
  );
}

export default CookieBannerWrapper;
