const getDefaultActivities = () =>
  new Set(process.env.REACT_APP_DEFAULT_ACTIVITIES.split(','));

const formData = {
  isCreation: false,
  serviceLevel: '1',
  showLocationFields: false,
  chosenCity: '',
  cityInfo: {},
  controlledSelectedItem: null,
  nbYearsBusiness: 0,
  chosenLegalForm: undefined,
  externalExpert: '',
  name: '',
  expense: '', // QUID ?
  yearsCollaboration: 0,
  chosenActivitiesData: [],
  chosenSkills: [],
  chosenSector: undefined,
  nbEmployees: 0,
  consultationType: '',
  turnover: 0,
  nbConsultations: 0,
  chosenTransferOption: '',
  revenue: '1',
  accountingEntry: 0,
  nbInvoices: 0,
  vat: 0,
  processingConsent: false,
  marketingConsent: false,
  chosenDays: [],
  companyName: '',
  lastName: '',
  firstName: '',
  phone: '',
  email: '',
  clientId: '',
  country: undefined,
  readyToSubmit: false,
};

const setDefaults = (data) => {
  data.isCreation = true;
  data.nbYearsBusiness = 0;
  data.yearsCollaboration = 0;
  data.externalExpert = null;
  data.cityInfo = {};
  data.chosenActivitiesData = Array.from(getDefaultActivities());
  data.chosenSkills = [];
  data.serviceLevel = '1';
  data.turnover = 0;
  data.consultationType = 1;
  data.nbConsultations = 4;
  data.revenue = '1';
  data.nbInvoices = '1';
  data.vat = false;
  data.readyToSubmit = false;
  data.chosenTransferOption = process.env.REACT_APP_DEFAULT_TRANSFEROPTION_ID;
  data.country = undefined;
  data.showLocationFields = false;
  data.controlledSelectedItem = null;

  return data;
};

export default { formData, setDefaults };
