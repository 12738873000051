import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import AnimatedRoutes from './Component/Template/animatedRoutes.js';
import ToastProvider from './Component/Contexts/ToastProvider.js';
import CookieProvider from './Component/Contexts/cookieProvider.js';
import CookieWrapper from './Component/Cookie/cookieWrapper.js';
import ClientProvider from './Component/Contexts/clientProvider.js';

function App() {
  return (
    <BrowserRouter>
      <ToastProvider>
        <CookieProvider>
          <ClientProvider>
            <AnimatedRoutes />
          </ClientProvider>
          <CookieWrapper />
        </CookieProvider>
      </ToastProvider>
    </BrowserRouter>
  );
}

export default App;
