import { useContext } from 'react';
import { ToastContext } from '../Contexts/contexts.js';

function useToast() {
  const toastContext = useContext(ToastContext);

  const enqueueToast = (message, options) =>
    toastContext.enqueueToast(message, options);

  return enqueueToast;
}

export default useToast;
