import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import '../../../css/devis/devis.css';
import axios from 'axios';
import useToast from '../../Toasts/useToast.js';
import {
  ToastHeaders,
  ToastMessages,
  ToastThemes,
} from '../../Toasts/ToastOptions/toastOptions.js';

const API_PATH =
  process.env.REACT_APP_API_URL ||
  'https://api.choisir-son-expert-comptable.fr/api/';

const Step6 = forwardRef((props, ref) => {
  const [transferOptions, setTransferOptions] = useState([]);
  const accountingEntry = '1';
  const [revenue, setRevenue] = useState('1');
  const [nbInvoices, setNbInvoices] = useState('1');
  const [vat, setVat] = useState(false);
  const [chosenTransferOption, setChosenTransferOption] = useState('');

  const enqueueToast = useToast();

  useImperativeHandle(ref, () => ({
    validateStep,
  }));

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchTransferOptions();
      } catch (error) {
        enqueueToast(ToastMessages.ERROR_OCCURED_FETCH, {
          theme: ToastThemes.toastThemes.ERROR_OCCURED,
          header: ToastHeaders[ToastThemes.toastThemes.ERROR_OCCURED],
          duration: 3000,
        });
        console.error('Erreur lors de la récupération des données', error);
      }
    };

    fetchData();
  }, []);

  /* const handleChangeAccountingEntry = (e) => {
    const { value } = e.target;

    setAccountingEntry(value);

    document.getElementById('accountingEntry').classList.remove('devis-error');
  }; */

  const handleOptionChanged = (e) => {
    const { value, name } = e.target;

    if (name === 'vat') {
      const boolValue = value === 'true';
      setVat(boolValue);
    } else if (name === 'nbInvoices') {
      setNbInvoices(value);
    } else if (name === 'revenue') {
      setRevenue(value);
    } else {
      setChosenTransferOption(value);
    }

    let input = null;
    Array.from(
      document.querySelectorAll(`input[type="radio"][name=${name}]`)
    ).forEach((element) => {
      input = element;
      input.classList.remove('devis-error');
    });
  };

  const validateStep = () => {
    let firstInvalidInput = null;
    let hasError = false;
    let inputWithError = null;

    if (!chosenTransferOption) {
      Array.from(
        document.querySelectorAll(
          'input[type="radio"][name="chosenTransferOption"]'
        )
      ).forEach((element) => {
        inputWithError = element;
        inputWithError.classList.add('devis-error');
        if (!firstInvalidInput) firstInvalidInput = inputWithError;
      });
      hasError = true;
    }

    if (!nbInvoices) {
      Array.from(
        document.querySelectorAll('input[type="radio"][name="nbInvoices"]')
      ).forEach((element) => {
        inputWithError = element;
        inputWithError.classList.add('devis-error');
        if (!firstInvalidInput) firstInvalidInput = inputWithError;
      });
      hasError = true;
    }

    if (!revenue) {
      Array.from(
        document.querySelectorAll('input[type="radio"][name="revenue"]')
      ).forEach((element) => {
        inputWithError = element;
        inputWithError.classList.add('devis-error');
        if (!firstInvalidInput) firstInvalidInput = inputWithError;
      });
      hasError = true;
    }

    if (vat === '') {
      Array.from(
        document.querySelectorAll('input[type="radio"][name="vat"]')
      ).forEach((element) => {
        inputWithError = element;
        inputWithError.classList.add('devis-error');
        if (!firstInvalidInput) firstInvalidInput = inputWithError;
      });
      hasError = true;
    }

    if (!accountingEntry) {
      inputWithError = document.getElementById('accountingEntry');
      inputWithError.classList.add('devis-error');
      hasError = true;
      if (!firstInvalidInput) firstInvalidInput = inputWithError;
    }

    const result = {
      error: hasError,
      data: { chosenTransferOption, revenue, accountingEntry, nbInvoices, vat },
    };

    if (hasError) {
      firstInvalidInput?.focus();
      firstInvalidInput?.reportValidity();
    }

    return result;
  };

  const fetchTransferOptions = async () => {
    try {
      const response = await axios.get(`${API_PATH}transfer-options`);
      const transferOptionsData = response.data;

      setTransferOptions(transferOptionsData);

      if (transferOptions) {
        setChosenTransferOption(transferOptionsData[0]?.id);
      }
    } catch (error) {
      enqueueToast(ToastMessages.ERROR_OCCURED_FETCH, {
        theme: ToastThemes.toastThemes.ERROR_OCCURED,
        header: ToastHeaders[ToastThemes.toastThemes.ERROR_OCCURED],
        duration: 3000,
      });
      console.error('Erreur lors de la récupération des données', error);
    }
  };

  return (
    <>
      <span className="devis-form-span">Chiffre d&apos;affaires annuel</span>
      <div className="devis-radio-btn devis-wrap-container">
        <div className="devis-width-50">
          <input
            type="radio"
            name="revenue"
            id="revenue-1"
            value="1"
            onChange={handleOptionChanged}
            checked={revenue === '1'}
          />
          <label htmlFor="revenue-1" className="devis-form-label-radio">
            Jusqu&apos;à 100.000€
          </label>
        </div>
        <div className="devis-width-50">
          <input
            type="radio"
            name="revenue"
            id="revenue-2"
            value="2"
            onChange={handleOptionChanged}
            checked={revenue === '2'}
          />
          <label htmlFor="revenue-2" className="devis-form-label-radio">
            100.000 à 500.000€
          </label>
        </div>
        <div className="devis-width-50">
          <input
            type="radio"
            name="revenue"
            id="revenue-3"
            value="3"
            onChange={handleOptionChanged}
            checked={revenue === '3'}
          />
          <label htmlFor="revenue-3" className="devis-form-label-radio">
            500.000 à 3 Millions
          </label>
        </div>
        <div className="devis-width-50">
          <input
            type="radio"
            name="revenue"
            id="revenue-4"
            value="4"
            onChange={handleOptionChanged}
            checked={revenue === '4'}
          />
          <label htmlFor="revenue-4" className="devis-form-label-radio">
            Plus de 3 Millions
          </label>
        </div>
      </div>

      <span className="devis-form-span">
        Estimations du nombre annuel de factures
      </span>

      <div className="devis-radio-btn devis-wrap-container">
        <div className="devis-width-33">
          <input
            type="radio"
            name="nbInvoices"
            id="nbInvoices-1"
            value="1"
            onChange={handleOptionChanged}
            checked={nbInvoices === '1'}
          />
          <label htmlFor="nbInvoices-1" className="devis-form-label-radio">
            Moins de 200
          </label>
        </div>
        <div className="devis-width-33">
          <input
            type="radio"
            name="nbInvoices"
            id="nbInvoices-2"
            value="2"
            onChange={handleOptionChanged}
            checked={nbInvoices === '2'}
          />
          <label htmlFor="nbInvoices-2" className="devis-form-label-radio">
            200 à 500
          </label>
        </div>
        <div className="devis-width-33">
          <input
            type="radio"
            name="nbInvoices"
            id="nbInvoices-3"
            value="3"
            onChange={handleOptionChanged}
            checked={nbInvoices === '3'}
          />
          <label htmlFor="nbInvoices-3" className="devis-form-label-radio">
            500 à 1500
          </label>
        </div>
        <div className="devis-width-33">
          <input
            type="radio"
            name="nbInvoices"
            id="nbInvoices-4"
            value="4"
            onChange={handleOptionChanged}
            checked={nbInvoices === '4'}
          />
          <label htmlFor="nbInvoices-4" className="devis-form-label-radio">
            1500 à 3000
          </label>
        </div>
        <div className="devis-width-33">
          <input
            type="radio"
            name="nbInvoices"
            id="nbInvoices-5"
            value="5"
            onChange={handleOptionChanged}
            checked={nbInvoices === '5'}
          />
          <label htmlFor="nbInvoices-5" className="devis-form-label-radio">
            Plus de 3000
          </label>
        </div>
      </div>

      {/* <span className="devis-form-span">Saisie de comptabilité</span>
      <div id="accountingEntry" className="devis-radio-btn">
        <div>
          <input
            type="radio"
            name="accountingEntry"
            value="1"
            onChange={handleChangeAccountingEntry}
            checked={accountingEntry === '1'}
          />
          Par le cabinet
        </div>
        <div>
          <input
            type="radio"
            name="accountingEntry"
            value="2"
            onChange={handleChangeAccountingEntry}
            checked={accountingEntry === '2'}
          />
          Par vous-même
        </div>
        <div>
          <input
            type="radio"
            name="accountingEntry"
            value="3"
            onChange={handleChangeAccountingEntry}
            checked={accountingEntry === '3'}
          />
          Externalisé
        </div>
      </div> */}

      <span className="devis-form-span">
        Société soumise à TVA (Taxe sur la valeur ajoutée)
      </span>

      <div className="devis-radio-btn">
        <div>
          <input
            type="radio"
            name="vat"
            id="vat-true"
            value={true}
            onChange={handleOptionChanged}
            checked={vat === true}
          />
          <label htmlFor="vat-true" className="devis-form-label-radio">
            Oui
          </label>
        </div>
        <div>
          <input
            type="radio"
            name="vat"
            id="vat-false"
            value={false}
            onChange={handleOptionChanged}
            checked={vat === false}
          />
          <label htmlFor="vat-false" className="devis-form-label-radio">
            Non
          </label>
        </div>
      </div>

      {/* <span className="devis-form-span">
        Transmission des documents à l&apos;expert-comptable
      </span>
      <div
        id="transfer-options"
        className="devis-radio-btn devis-wrap-container"
      >
        {transferOptions?.map((transferOption) => (
          <div key={transferOption.id}>
            <input
              type="radio"
              name="chosenTransferOption"
              value={transferOption.id}
              id={transferOption.id}
              onChange={handleOptionChanged}
              checked={chosenTransferOption === transferOption.id}
            />
            <label
              htmlFor={transferOption.id}
              className="devis-form-label-radio"
            >
              {transferOption.label}
            </label>
          </div>
        ))}
      </div> */}
    </>
  );
});

Step6.displayName = 'Step6';

export default Step6;
