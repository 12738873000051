import React from 'react';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import { Link } from 'react-router-dom';

function blockRenderer(section, templateStyle) {
  const className = `template-${templateStyle}-content-paragraph`;

  return (
    <BlocksRenderer
      content={section?.Paragraphs}
      blocks={{
        paragraph: ({ children }) => <p className={className}>{children}</p>,
        list: (props) => {
          if (props.format === 'ordered') {
            return <ol>{props.children}</ol>;
          }

          return <ul>{props.children}</ul>;
        },
        'list-item': (props) => <li className={className}>{props.children}</li>,
        link: ({ children, url }) => (
          <Link
            className="link-article"
            target="_blank"
            rel="noreferrer"
            to={url}
          >
            {children}
          </Link>
        ),
      }}
      modifiers={{
        bold: (props) => (
          <strong className={`${className}bold`}>{props.children}</strong>
        ),
        italic: (props) => (
          <em className={`${className}italic`}>{props.children}</em>
        ),
        underline: (props) => (
          <u className={`${className}underline`}>{props.children}</u>
        ),
        strikethrough: (props) => (
          <del className={`${className}strikethrough`}>{props.children}</del>
        ),
        code: (props) => (
          <code className={`${className}code`}>{props.children}</code>
        ),
      }}
    />
  );
}

export default blockRenderer;
