import api from './axios.js';

const findActivities = async () => {
  const { data } = await api.get(`/activities`);

  return data;
};

const activitiesServices = {
  findActivities,
};

export default activitiesServices;
