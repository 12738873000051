import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import '../../css/service.css';
import wallpaperPNG from '../../css/images/mockup-accueil-cec-2.png';
import wallpaperWEBP from '../../css/images/mockup-accueil-cec-2.webp';
import PreloadImage from '../Image/image.js';

const Service = () => (
  <div className="services">
    <Helmet>
      <link rel="preload" as="image" href={wallpaperWEBP} type="image/webp" />
    </Helmet>
    <div className="services-subcontainer">
      <div className="row justify-content-start">
        <h1 className="mainTitle" title="expert comptable">
          CEC, votre comparateur
          <br /> <span className="mainTitle">d&apos;experts-comptables</span>
        </h1>
      </div>

      <div className="row justify-content-start">
        <p className="services-subtext">
          Utilisez CEC et recevez rapidement{' '}
          <strong>3 devis d'experts-comptables</strong> adaptés à vos besoins,
          le tout gratuitement!
        </p>
      </div>

      <div className="row justify-content-start services-row-btn">
        <Link to="/devis" className="m-1 services-btn-find">
          Devis Gratuits Cliquez Ici !
        </Link>
      </div>
    </div>
    <div className="services-subcontainer">
      <div className="services-wp">
        <PreloadImage
          srcWebP={wallpaperWEBP}
          srcFallback={wallpaperPNG}
          alt="Illustration du tableau de bord CEC"
        />
      </div>
    </div>
  </div>
);

export default Service;
