import React from 'react';
import Header from './Header.js';
import Service from './Service.js';
import Solution from './Solution.js';
import Demande from './Demande.js';
import Explain from './Explain.js';
import Review from './Review.js';
import Footer from './Footer.js';
import Footerextented from './Footerextented.js';
import Partners from './Partners.js';

import { motion } from 'framer-motion';
import SEO from '../SEO/seo.js';

const Homepage = () => (
  <motion.div>
    <SEO />
    <Header />
    <Service />
    <Partners />
    <Explain />
    <Solution />
    <Demande />
    <Review />
    <Footerextented />
    <Footer />
  </motion.div>
);

export default Homepage;
