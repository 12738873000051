import React from 'react';
import '../../css/demande.css';
import devisPNG from '../../css/images/man-woman-happy-contract-snap.jpg';
import devisWEBP from '../../css/images/man-woman-happy-contract-snap.webp';
const Demande = () => (
  <div className="demande-container">
    <div className="demande-grid">
      <div className="demande-inner-grid">
        <div className="title-plateforme">
          <span className="title-bluesoft">
            A qui est destiné le comparateur
            <br />
          </span>{' '}
          d'expert-comptable CEC ?
        </div>
        <div className="demande-icon-text">
          <div className="destiny">
            <h3 className="title-red">Commerçant et artisan</h3>
            <p>• Trouvez rapidement un expert-comptable au meilleur tarif.</p>
            <p>
              • Bénéficiez de conseils personnalisés adaptés à votre activité.
            </p>
          </div>
        </div>
        <div className="demande-icon-text">
          <div className="destiny">
            <h3 className="title-blue">Startup innovante </h3>
            <p>
              • Boostez votre entreprise avec un expert-comptable spécialisé
              dans votre domaine (legaltech, fintech, digital, industriel...).
            </p>
            <p>
              • Profitez d'un accompagnement sur-mesure pour répondre à vos
              besoins spécifiques.
            </p>
          </div>
        </div>
        <div className="demande-icon-text">
          <div className="destiny">
            <h3 className="title-green">
              Professionnels médicaux et paramédicaux
            </h3>
            <p>• Facilitez votre recherche d'expert-comptable.</p>
            <p>
              • Choisissez parmi des devis d'experts-comptables qualifiés dans
              le domaine médical.
            </p>
          </div>
        </div>
        <div className="demande-icon-text">
          <div className="destiny">
            <h3 className="title-yellow">Création d'entreprise</h3>
            <p>
              • Recevez un soutien pour trouver le comptable parfait pour votre
              future entreprise.
            </p>
            <p>
              • Bénéficiez de conseils personnalisés adaptés à vos besoins et
              critères.
            </p>
          </div>
        </div>
      </div>
      <div className="destiny-img">
        <picture className="demande-grid-picture">
          <source srcSet={devisWEBP} />
          <img className="demande-picture" src={devisPNG} alt="" />
        </picture>
      </div>
    </div>
  </div>
);

export default Demande;
