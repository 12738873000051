import React, { useEffect, useState } from 'react';
import Header from '../Header.js';
import Footer from '../Footer.js';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import TemplateImg from './Sections/TemplateImg.js';
import '../../../css/article.css';
import TemplateSimple from './Sections/TemplateSimple.js';
import TemplateColumns from './Sections/TemplateColumns.js';
import TemplateCenter from './Sections/TemplateCenter.js';
import Error404 from '../404.js';
import TemplateRelated from './Sections/TemplateRelated.js';
import SEO from '../../SEO/seo.js';
import Footerextented from '../Footerextented.js';

const defaultStrapiGetUrl = `${process.env.REACT_APP_STRAPI_API_URL}articles?populate=*&filters[Slug][$eq]=`;

function Article(props) {
  const [article, setArticle] = useState({});

  const [sections, setSections] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [onError, setOnError] = useState(false);

  const { id } = useParams();

  const getArticleAPIRequest = async () => {
    const { data } = await axios.get(`${defaultStrapiGetUrl}${id}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_STRAPI_GET_TOKEN}`,
      },
    });

    if (data?.data.length === 0) {
      throw new Error('No article found');
    }

    return data;
  };

  const getArticle = async () => {
    try {
      const data = await getArticleAPIRequest();

      setIsLoading(false);
      setArticle(data?.data[0] || {});
      sortSections(data?.data[0] || {});
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      setOnError(true);
    }
  };

  const sortSections = (data) => {
    const attr = data?.attributes;
    let sortSections = [];

    if (attr === undefined) return;

    sectionAttributes.forEach((sectionAttr) => {
      if (attr[sectionAttr] !== undefined && attr[sectionAttr]?.length > 0) {
        attr[sectionAttr].forEach((sectionItem) => {
          sortSections.push(sectionItem);
        });
      }
    });

    sortSections = sortSections.sort((item, item1) => {
      if (item?.Order < item1?.Order) return -1;
      else if (item?.Order > item1?.Order) return 1;

      return 0;
    });

    setSections(sortSections);
  };

  useEffect(() => {
    getArticle();
  }, []);

  const getSections = () => {
    const sectionList = [];

    if (sections.length > 0) {
      sections.forEach((section) => {
        switch (section?.Template_type) {
          case 'Template_img':
            sectionList.push(
              <TemplateImg section={section} key={`tmp-img-${section?.id}`} />
            );
            break;
          case 'Template_section':
            sectionList.push(
              <TemplateSimple
                section={section}
                key={`tmp-simple-${section?.id}`}
              />
            );
            break;
          case 'Template_section_columns':
            sectionList.push(
              <TemplateColumns
                section={section}
                key={`tmp-columns-${section?.id}`}
              />
            );
            break;
          case 'Template_section_center':
            sectionList.push(
              <TemplateCenter
                section={section}
                key={`tmp-center-${section?.id}`}
              />
            );
            break;
        }
      });
    }

    return sectionList;
  };

  const item = article?.attributes;

  return (
    <>
      {onError && <Error404 pageName={props.pageName} />}
      {!onError && (
        <>
          <Header pageName={props.pageName} />
          <SEO
            pageType="article"
            pageTitle={article?.attributes?.Title}
            pageDescription={article?.attributes?.Description}
            pageURL={`https://www.choisir-son-expert-comptable.fr/articles/${article?.attributes?.Slug}`}
          />
          <div className="article-page">
            {isLoading && (
              <h1 className="article-page-loader">Chargement ...</h1>
            )}
            {item !== undefined && (
              <>
                <div className="article-page-red-link">
                  <span className="article-red-link-item">
                    <Link to={'/articles'}>Accueil</Link>
                  </span>
                  <span className="article-separation"></span>
                  <span className="article-red-link-item">
                    {ActivityDomains[article?.attributes?.Type]}
                  </span>
                  <span className="article-separation"></span>
                  <span className="article-red-link-item article-item-underline">
                    {item?.CommonThread}
                  </span>
                </div>
                <h1 className="article-page-title">{item?.Title}</h1>
                {sections.length > 0 && getSections()}
                {article?.attributes?.Related_articles?.length > 0 && (
                  <TemplateRelated
                    articles={article?.attributes?.Related_articles}
                  />
                )}
              </>
            )}
          </div>
          <Footerextented />
          <Footer />
        </>
      )}
    </>
  );
}

export default Article;

Article.propTypes = {
  pageName: PropTypes.string,
};

const sectionAttributes = [
  'Template_image',
  'Template_section',
  'Template_section_columns',
  'Template_center_section',
];

const ActivityDomains = {
  default: "Domaine d'activité",
  Taxation: 'Fiscalité',
  National_law: 'Loi Nationale', // eslint-disable-line
  General_information: 'Informations Générales', // eslint-disable-line
  Accounting: 'Comptabilité',
};
