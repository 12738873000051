import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import '../../../css/devis/devis.css';
import '../../../css/steps/step1.css';
import axios from 'axios';
import {
  ToastHeaders,
  ToastMessages,
  ToastThemes,
} from '../../Toasts/ToastOptions/toastOptions.js';
import useToast from '../../Toasts/useToast.js';
import SearchComponent from '../../Autocomplete/autocomplete.js';
import PropTypes from 'prop-types';
import { countriesOptions } from '../../../static/data/countries/countries.data.js';

const API_PATH =
  process.env.REACT_APP_API_URL || 'https://api.choisir-son-expert.fr/api/';

const Step1 = forwardRef((props, ref) => {
  const [showLocationFields, setShowLocationFields] = useState(
    props?.model?.showLocationFields
  );
  const [startUpTypes, setStartUpTypes] = useState([]);
  const [isStartUp, setIsStartUp] = useState(false);
  const [locations, setLocations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [locationsFormatted, setLocationsFormatted] = useState([]);
  const [legalForms, setLegalForms] = useState(props?.model?.legalForms || []);
  const [chosenCity, setChosenCity] = useState(props?.model?.chosenCity || '');
  const [controlledSelectedItem, setControlledSelectedItem] = useState(
    props?.model?.controlledSelectedItem || null
  );
  const [nbYearsBusiness, setNbYearsBusiness] = useState(
    props?.model?.nbYearsBusiness || 0
  );
  const [chosenLegalForm, setChosenLegalForm] = useState(
    props?.model?.chosenLegalForm || ''
  );
  const [externalExpert, setExternalExpert] = useState(
    props?.model?.externalExpert || false
  );
  const [name, setName] = useState(props?.model?.name || '');
  const [yearsCollaboration, setYearsCollaboration] = useState(
    props?.model?.yearsCollaboration || ''
  );
  const [expense, setExpense] = useState(props?.model?.expense || '');
  const [country, setCountry] = useState(props?.model?.country || 'France');
  const [isOpen, setIsOpen] = useState(false);

  const enqueueToast = useToast();

  useImperativeHandle(ref, () => ({
    validateStep,
  }));

  useEffect(() => {
    setShowLocationFields(props.model?.showLocationFields);
    updateLocationByCountry();
    setControlledSelectedItem(props?.model?.controlledSelectedItem);
    setChosenCity(props?.model?.chosenCity);
  }, [props.model]);

  useEffect(() => {
    if (props?.currentStep !== 1) return;

    const elm = document.getElementById('form-step-1');

    if (elm === null) return;

    setTimeout(() => {
      elm.style.maxHeight = `${elm.scrollHeight + 45}px`;
    }, 100);

    setTimeout(() => {
      elm.style.maxHeight = `${elm.scrollHeight + 45}px`;
    }, 200);
  }, [isOpen, country]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([fetchLegalForms(), fetchLocations()]);
      } catch (error) {
        enqueueToast(ToastMessages.ERROR_OCCURED_FETCH, {
          theme: ToastThemes.toastThemes.ERROR_OCCURED,
          header: ToastHeaders[ToastThemes.toastThemes.ERROR_OCCURED],
          duration: 3000,
        });
        console.error('Erreur lors de la récupération des données', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (showLocationFields) {
      const elm = document.getElementById('form-step-1');
      elm.style.maxHeight = `${elm.scrollHeight + 45}px`;
    }
  }, [showLocationFields]);

  useEffect(() => {
    updateLocationByCountry();
  }, [country]);

  const updateLocationByCountry = () => {
    if (showLocationFields) {
      setChosenCity(null);
    }

    setControlledSelectedItem(null);

    const locationMap = locations.filter(
      (location) => location.country === country
    );

    const departmentsData = Array.from(
      new Set(locationMap.map((location) => location.department))
    );
    const citiesData = locationMap.map(
      (item) => `${item.city} (${item.zipCode})`
    );
    setDepartments(departmentsData);
    setLocationsFormatted([...citiesData, ...departmentsData]);
  };

  const handleChangeLocation = (selectedLocation) => {
    const chosenLocation = selectedLocation;

    setControlledSelectedItem(selectedLocation);

    if (!chosenLocation) {
      setChosenCity(null);

      return;
    }

    if (departments.includes(chosenLocation)) {
      const citiesData = locations.filter(
        (location) => location.department === chosenLocation
      );

      const cityIds = citiesData.map((city) => city.id);
      setChosenCity(cityIds);

      return;
    }

    const [, city] = chosenLocation.match(/(.*) \(.*\)/);
    const [chosenCityData] = locations.filter(
      (location) => location.city === city
    );
    setChosenCity([chosenCityData?.id]);
    const [input] = document.getElementsByClassName('comboBox');
    input?.classList.remove('devis-error');
  };

  const handleChangeLegalForm = (e) => {
    const { value } = e.target;

    setChosenLegalForm(value);

    const input =
      document.getElementById('legalForm') ||
      document.getElementById('startUpTypes');
    input?.classList?.remove('devis-error');
    input?.setCustomValidity('');
  };

  const handleChangePreference = (e) => {
    setShowLocationFields(e.target.value === 'in-person');
    setChosenCity('');

    Array.from(
      document.querySelectorAll(`input[type="radio"][name="preference"]`)
    ).forEach((element) => {
      element.classList.remove('devis-error');
    });
  };

  const onOptionChanged = (e) => {
    const { value } = e.target;
    const boolValue = value === 'true';

    if (!boolValue) {
      document.getElementById('name').classList.remove('devis-error');
      document
        .getElementById('yearsCollaboration')
        .classList.remove('devis-error');
      document.getElementById('expense').classList.remove('devis-error');
      setName('');
      setYearsCollaboration('');
      setExpense('');
    }

    setIsOpen(boolValue);
    setExternalExpert(boolValue);
  };

  const onNameChanged = (e) => {
    const { value, id } = e.target;

    setName(value);

    document.getElementById(id).classList.remove('devis-error');
  };

  const handleYearsCollaborationChanged = (e) => {
    const { id, value } = e.target;

    const regex = /^[0-9\b]+$/;

    if (value === '' || regex.test(value)) {
      setYearsCollaboration(value === '' ? '' : Math.abs(value));
    }

    document.getElementById(id).classList.remove('devis-error');
  };

  const handleExpenseChanged = (e) => {
    const { id, value } = e.target;

    const regex = /^[0-9\b]+$/;

    if (value === '' || regex.test(value)) {
      setExpense(value === '' ? '' : Math.abs(value));
    }

    document.getElementById(id).classList.remove('devis-error');
  };

  const handleChangeYears = (e) => {
    const { value } = e.target;

    const regex = /^[0-9\b]+$/;

    if (value === '' || regex.test(value)) {
      setNbYearsBusiness(value === '' ? '' : Math.abs(value));
    }

    document.getElementById('nbYearsBusiness').classList.remove('devis-error');
  };

  const handleKeyPress = (e) => {
    // Autoriser les touches de contrôle
    if (
      e.which === 0 ||
      e.which === 8 ||
      e.which === 46 ||
      e.keyCode === 37 || // Flèche gauche
      e.keyCode === 38 || // Flèche haut
      e.keyCode === 39 || // Flèche droite
      e.keyCode === 40
    ) {
      return;
    }

    // Vérifie si le caractère n'est pas un chiffre (0-9) et empêche l'entrée
    if (!e.key.match(/\d/)) {
      e.preventDefault();
    }
  };

  const validateStep = () => {
    let firstInvalidInput = null;
    let hasError = false;
    let inputWithError = null;

    if (showLocationFields === '') {
      Array.from(
        document.querySelectorAll('input[type="radio"][name="preference"]')
      ).forEach((element) => {
        inputWithError = element;
        inputWithError.classList.add('devis-error');
        if (!firstInvalidInput) firstInvalidInput = inputWithError;
      });
      inputWithError = true;
    }

    if (showLocationFields && !chosenCity) {
      [inputWithError] = document.getElementsByClassName('comboBox');
      inputWithError?.classList.add('devis-error');
      inputWithError?.setCustomValidity(
        `Vous devez choisir une ville ou un département`
      );
      hasError = true;
      if (!firstInvalidInput) firstInvalidInput = inputWithError;
    }

    if (nbYearsBusiness === '' || parseInt(nbYearsBusiness, 10) < 0) {
      inputWithError = document.getElementById('nbYearsBusiness');
      inputWithError.classList.add('devis-error');
      hasError = true;
      if (!firstInvalidInput) firstInvalidInput = inputWithError;
    }

    if (!chosenLegalForm) {
      inputWithError =
        document.getElementById('legalForm') ||
        document.getElementById('startUpTypes');
      inputWithError.classList.add('devis-error');
      hasError = true;
      inputWithError?.setCustomValidity(
        `Vous devez choisir une forme juridique ou un type d'activité`
      );
      if (!firstInvalidInput) firstInvalidInput = inputWithError;
    }

    if (externalExpert) {
      if (name === '') {
        inputWithError = document.getElementById('name');
        inputWithError.classList.add('devis-error');
        hasError = true;
        if (!firstInvalidInput) firstInvalidInput = inputWithError;
      }

      if (yearsCollaboration === '' || yearsCollaboration < 0) {
        inputWithError = document.getElementById('yearsCollaboration');
        inputWithError.classList.add('devis-error');
        hasError = true;
        if (!firstInvalidInput) firstInvalidInput = inputWithError;
      }

      if (expense === '' || expense < 0) {
        inputWithError = document.getElementById('expense');
        inputWithError.classList.add('devis-error');
        hasError = true;
        if (!firstInvalidInput) firstInvalidInput = inputWithError;
      }
    }

    const result = {
      error: hasError,
      data: {
        chosenCity,
        nbYearsBusiness,
        chosenLegalForm,
        externalExpert,
        name,
        expense,
        yearsCollaboration,
        showLocationFields,
        controlledSelectedItem,
        country,
      },
    };

    if (hasError) {
      firstInvalidInput?.focus();
      firstInvalidInput?.reportValidity();
    }

    return result;
  };

  const fetchLocations = async () => {
    try {
      const response = await axios.get(`${API_PATH}locations/experts`);
      const locationsData = response.data;

      const departmentsData = Array.from(
        new Set(locationsData.map((location) => location.department))
      );
      const citiesData = locationsData.map(
        (item) => `${item.city} (${item.zipCode})`
      );
      setDepartments(departmentsData);
      setLocations(locationsData);
      setLocationsFormatted([...citiesData, ...departmentsData]);
    } catch (error) {
      enqueueToast(ToastMessages.ERROR_OCCURED_FETCH, {
        theme: ToastThemes.toastThemes.ERROR_OCCURED,
        header: ToastHeaders[ToastThemes.toastThemes.ERROR_OCCURED],
        duration: 3000,
      });
      console.error('Erreur lors de la récupération des données', error);
    }
  };

  const fetchLegalForms = async () => {
    try {
      const response = await axios.get(`${API_PATH}legal-forms`);
      const legalFormsData = response.data;

      const startUpTypesFiltered = legalFormsData.filter((obj) =>
        obj.name.includes('Startup')
      );
      const legalFormsFiltered = legalFormsData.filter(
        (obj) => !obj.name.includes('Startup')
      );

      setStartUpTypes(startUpTypesFiltered);
      setLegalForms(legalFormsFiltered);
    } catch (error) {
      enqueueToast(ToastMessages.ERROR_OCCURED_FETCH, {
        theme: ToastThemes.toastThemes.ERROR_OCCURED,
        header: ToastHeaders[ToastThemes.toastThemes.ERROR_OCCURED],
        duration: 3000,
      });
      console.error('Erreur lors de la récupération des données', error);
    }
  };

  const handleCountryChange = (e) => {
    const { value } = e.target;

    setCountry(value);
  };

  return (
    <>
      <>
        <span className="devis-form-span">
          Dans quel pays vous situez-vous ?
        </span>
        <select
          name="country"
          id="country"
          value={country}
          autoComplete="country-name"
          onChange={(e) => handleCountryChange(e)}
          className="devis-form-input"
        >
          {countriesOptions.map((countryOption) => (
            <option key={countryOption.value} value={countryOption.value}>
              {countryOption.label}
            </option>
          ))}
        </select>
        <span className="devis-form-span">
          Préférence de rencontre avec votre expert-comptable
        </span>
        <div className="devis-radio-btn devis-wrap-container">
          <div>
            <input
              type="radio"
              id="in-person"
              value="in-person"
              name="preference"
              onChange={handleChangePreference}
              checked={showLocationFields === true}
            />
            <label htmlFor="in-person" className="devis-form-label-radio">
              Rencontre en personne avec l'expert-comptable.
            </label>
          </div>
          <div>
            <input
              type="radio"
              id="distance"
              value="distance"
              name="preference"
              onChange={handleChangePreference}
              checked={showLocationFields === false}
            />
            <label htmlFor="distance" className="devis-form-label-radio">
              {' '}
              Relation professionnelle à distance (visioconférence).
            </label>
          </div>
        </div>
      </>
      {showLocationFields && (
        <SearchComponent
          allData={locationsFormatted}
          label="Ville ou Département où rencontrer votre expert-comptable"
          placeholder="Ex: Metz ou Moselle"
          setSelectedItem={handleChangeLocation}
          enableCreation={false}
          selectedItem={controlledSelectedItem}
        />
      )}
      {!props?.model?.isCreation && (
        <div className="devis-small-input">
          <label htmlFor="nbYearsBusiness" className="devis-form-label">
            Nombres d&apos;années d&apos;existence de votre societé
          </label>
          <input
            type="number"
            id="nbYearsBusiness"
            name="nbYearsBusiness"
            value={nbYearsBusiness}
            onChange={handleChangeYears}
            onKeyDown={handleKeyPress}
          />
        </div>
      )}
      <div>
        <span className="devis-form-span">
          Votre société est-elle une Start-Up ?{' '}
        </span>
        <div className="devis-radio-btn">
          <div>
            <input
              type="radio"
              name="startUp"
              value={true}
              onChange={() => {
                setIsStartUp(true);
                setChosenLegalForm(null);
              }}
              checked={isStartUp === true}
            />
            Oui
          </div>
          <div>
            <input
              type="radio"
              name="startUp"
              value={false}
              onChange={() => {
                setIsStartUp(false);
                setChosenLegalForm(null);
              }}
              checked={isStartUp === false}
            />
            Non
          </div>
        </div>
      </div>
      {isStartUp && (
        <div className="form-input-container">
          <label htmlFor="startUpTypes" className="devis-form-label">
            Domaine d&apos;activité de votre Start-Up
          </label>
          <select
            name="startUpTypes"
            id="startUpTypes"
            value={chosenLegalForm}
            className="devis-form-input"
            onChange={handleChangeLegalForm}
          >
            <option value="">Choisissez un domaine d&apos;activité</option>
            {startUpTypes?.map((startUpType) => (
              <option key={startUpType.id} value={startUpType.id}>
                {startUpType.name}
              </option>
            ))}
          </select>
        </div>
      )}
      {!isStartUp && (
        <div className="form-input-container">
          <label htmlFor="legalForm" className="devis-form-label">
            Forme juridique de votre société
          </label>
          <select
            name="legalForm"
            id="legalForm"
            value={chosenLegalForm}
            className="devis-form-input"
            onChange={handleChangeLegalForm}
          >
            <option value="">Choisissez une forme juridique</option>
            {legalForms?.map((legalForm) => (
              <option key={legalForm.id} value={legalForm.id}>
                {legalForm.name}
              </option>
            ))}
          </select>
        </div>
      )}

      {!props?.model?.isCreation && (
        <div>
          <span className="devis-form-span">
            Avez-vous déjà un expert-comptable ?
          </span>
          <div className="devis-radio-btn">
            <div>
              <input
                type="radio"
                name="expert"
                value={true}
                onChange={onOptionChanged}
                checked={externalExpert === true}
              />
              Oui
            </div>
            <div>
              <input
                type="radio"
                name="expert"
                value={false}
                onChange={onOptionChanged}
                checked={externalExpert === false}
              />
              Non
            </div>
          </div>
        </div>
      )}
      {isOpen && (
        <div
          className={`devis-form-completion ${
            isOpen === false ? 'labelOff' : ''
          }`}
        >
          <label htmlFor="name" className="devis-form-label ">
            {' '}
            Nom de famille de l&apos;expert-comptable{' '}
          </label>
          <input
            className="devis-form-input-no-arrow"
            id="name"
            value={name}
            placeholder="Ex: Dupont"
            autoComplete="off"
            onChange={onNameChanged}
            disabled={!isOpen}
          />

          <div className="devis-medium-input">
            <label htmlFor="yearsCollaboration" className="devis-form-label">
              Nombres d&apos;années de collaboration
            </label>
            <input
              type="number"
              id="yearsCollaboration"
              placeholder="Ex: 8"
              value={yearsCollaboration}
              onChange={handleYearsCollaborationChanged}
              onKeyDown={handleKeyPress}
              disabled={!isOpen}
            />
          </div>
          <div className="devis-medium-input">
            <label htmlFor="expense" className="devis-form-label">
              Montant actuel des charges comptables TTC
            </label>
            <input
              type="number"
              id="expense"
              placeholder="Ex: 3654"
              value={expense}
              onChange={handleExpenseChanged}
              onKeyDown={handleKeyPress}
              disabled={!isOpen}
            />
          </div>
        </div>
      )}
    </>
  );
});

Step1.propTypes = {
  model: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  currentStep: PropTypes.number,
};

Step1.displayName = 'Step1';

export default Step1;
