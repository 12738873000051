import React from 'react';
import { Link } from 'react-router-dom';
import Header from './Header.js';
import PropTypes from 'prop-types';
import '../../css/404.css';

const Error404 = (props) => (
  <>
    <Header pageName={props.pageName} />{' '}
    <div className="error-container">
      <h2>ERREUR 404</h2>
      <p className="error-text">
        Oups, il semble que cette page n'existe pas ...
      </p>
      <Link to="/" className="m-1 services-btn-find">
        Retourner à l'accueil
      </Link>
    </div>
  </>
);

Error404.propTypes = {
  pageName: PropTypes.string,
};

export default Error404;
