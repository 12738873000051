import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import '../../../css/devis/devis.css';
import axios from 'axios';
import PropTypes, { object } from 'prop-types';
import useToast from '../../Toasts/useToast.js';
import {
  ToastMessages,
  ToastHeaders,
  ToastThemes,
} from '../../Toasts/ToastOptions/toastOptions.js';
import SearchComponent from '../../Autocomplete/autocomplete.js';
import { PhoneInput } from 'react-international-phone';
import { PhoneNumberUtil } from 'google-libphonenumber';
// eslint-disable-next-line import/extensions
import 'react-international-phone/style.css';
import { ClientContext } from '../../Contexts/contexts.js';

const API_PATH =
  process.env.REACT_APP_API_URL ||
  'https://api.choisir-son-expert-comptable.fr/api/';

const weekDays = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi'];

const cityAPIQuery =
  'https://data.opendatasoft.com/api/explore/v2.1/catalog/datasets/geonames-postal-code@public/records?';

const Step7 = forwardRef((props, ref) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [locations, setLocations] = useState([]);
  const [formattedLocations, setFormattedLocations] = useState([]);
  const [city, setCity] = useState('');
  const [days, setDays] = useState([]);
  const [companyName, setCompanyName] = useState(
    props.model?.companyName || ''
  );
  const [lastName, setLastName] = useState(props.model?.lastName || '');
  const [firstName, setFirstName] = useState(props.model?.firstName || '');
  const [phone, setPhone] = useState(props.model?.phone || '');
  const [email, setEmail] = useState(props.model?.email || '');
  const [chosenDays, setChosenDays] = useState(props.model?.chosenDays || []);
  const [marketingConsent, setMarketingConsent] = useState(
    props.model?.marketingConsent || false
  );
  const [processingConsent, setProcessingConsent] = useState(
    props.model?.processingConsent || false
  );
  const [clientId, setClientId] = useState(props.model?.clientId || '');

  const enqueueToast = useToast();

  const clientContext = useContext(ClientContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([fetchDays(), fetchLocations()]);
      } catch (error) {
        enqueueToast(ToastMessages.ERROR_OCCURED_FETCH, {
          theme: ToastThemes.toastThemes.ERROR_OCCURED,
          header: ToastHeaders[ToastThemes.toastThemes.ERROR_OCCURED],
          duration: 3000,
        });
        console.error('Erreur lors de la récupération des données', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          RequestClientDaysCreation(),
          RequestClientNeedsCreation(),
          RequestClientSkillsCreation(),
          RequestExternalExpertCreation(),
        ]);

        await RequestClientQuotesCreation();
        props.updateCliendId(clientId);
        clientContext.setClientId(clientId);
      } catch (error) {
        enqueueToast(ToastMessages.ERROR_OCCURED_FETCH, {
          theme: ToastThemes.toastThemes.ERROR_OCCURED,
          header: ToastHeaders[ToastThemes.toastThemes.ERROR_OCCURED],
          duration: 3000,
        });
        console.error('Erreur lors de la récupération des données', error);
      }
    };

    if (clientId) {
      fetchData();
    }
  }, [clientId]);

  useImperativeHandle(ref, () => ({
    validateStep,
    RequestClientCreation,
  }));

  const handleValueChanged = (e) => {
    const { value, id } = e.target;

    if (id === 'companyName') {
      setCompanyName(value);
    } else if (id === 'lastName') {
      setLastName(value);
    } else if (id === 'firstName') {
      setFirstName(value);
    } else if (id === 'email') {
      setEmail(value);
    }

    const input = document.getElementById(id);
    input.classList.remove('devis-error');
    input.setCustomValidity('');
  };

  const handleRdvChanged = (e) => {
    const { id } = e.target;

    setChosenDays((prevState) => {
      // Copy the existing Set
      const chosenDays = new Set(prevState);

      if (chosenDays.has(id)) {
        // If the activity is already chosen, remove it
        chosenDays.delete(id);
      } else {
        // If the activity is not currently chosen, add it
        chosenDays.add(id);
      }

      return Array.from(chosenDays);
    });

    Array.from(document.getElementsByClassName('devis-checkbox')).forEach(
      (element) => {
        element.classList.remove('devis-checkbox-error');
        element.setCustomValidity('');
      }
    );
  };

  const phoneUtil = PhoneNumberUtil.getInstance();

  const isPhoneValid = (phone) => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
      return false;
    }
  };

  const isCityValid = async (city) => {
    try {
      const cityInfo = await axios.get(
        `${cityAPIQuery}where=place_name%20like%20%22${city}%22%20and%20not%20postal_code%20like%20%22CEDEX%22%20and%20(country_code%20%3D%20%22FR%22%20or%20country_code%20%3D%20%22LU%22)&limit=1`
      );

      return cityInfo?.data?.results[0];
    } catch (error) {
      return false;
    }
  };

  const handleChangeLocation = (selectedLocation) => {
    const chosenLocation = selectedLocation;

    if (
      chosenLocation &&
      !chosenLocation?.isCreation &&
      typeof chosenLocation !== object
    ) {
      if (chosenLocation.match(/(.*) \(.*\)/) !== null) {
        const [, cityMatch] = chosenLocation.match(/(.*) \(.*\)/);
        const [chosenCityData] = locations.filter(
          (location) => location.city === cityMatch
        );
        setCity(chosenCityData?.id);
      } else {
        setCity({ value: chosenLocation, isCreation: true });
      }
    } else {
      setCity(chosenLocation);
    }

    document
      .getElementsByClassName('comboBox')[0]
      .classList.remove('devis-error');
  };

  const handleConsentChanged = (e) => {
    const { id } = e.target;

    if (id === 'marketingConsent') {
      setMarketingConsent((prevState) => !prevState);
    } else {
      setProcessingConsent((prevState) => !prevState);
    }

    const input = document.getElementById(id);
    input.classList.remove('devis-checkbox-error');
    input.setCustomValidity('');
  };

  const checkEmail = async (email) => {
    try {
      const response = await axios.get(
        `${API_PATH}clients/check-email?email=${email}`
      );

      return response.data?.exists;
    } catch (error) {
      enqueueToast(ToastMessages.ERROR_OCCURED_FETCH, {
        theme: ToastThemes.toastThemes.ERROR_OCCURED,
        header: ToastHeaders[ToastThemes.toastThemes.ERROR_OCCURED],
        duration: 3000,
      });
      console.error('Erreur lors de la récupération des données', error);
    }
  };

  const validateStep = async () => {
    setIsSubmitting(true);
    let firstInvalidInput = null;
    let hasError = false;
    let inputWithError = null;
    let re = null;
    let cityInfo = null;

    if (!companyName) {
      inputWithError = document.getElementById('companyName');
      inputWithError.classList.add('devis-error');
      hasError = true;
      if (!firstInvalidInput) firstInvalidInput = inputWithError;
    }

    if (!city) {
      [inputWithError] = document.getElementsByClassName('comboBox');
      inputWithError?.classList.add('devis-error');
      inputWithError?.setCustomValidity(
        `Vous devez choisir une ville ou un département`
      );
      hasError = true;
      if (!firstInvalidInput) firstInvalidInput = inputWithError;
    } else if (city?.isCreation) {
      cityInfo = await isCityValid(city.value ? city.value : city);

      if (!cityInfo || Object.keys(cityInfo).length === 0) {
        [inputWithError] = document.getElementsByClassName('comboBox');
        inputWithError?.classList.add('devis-error');
        inputWithError?.setCustomValidity(
          `Aucune ville ne correspond à votre saisie`
        );
        hasError = true;
        if (!firstInvalidInput) firstInvalidInput = inputWithError;
      }
    }

    if (!lastName) {
      inputWithError = document.getElementById('lastName');
      inputWithError.classList.add('devis-error');
      hasError = true;
      if (!firstInvalidInput) firstInvalidInput = inputWithError;
    }

    if (!firstName) {
      inputWithError = document.getElementById('firstName');
      inputWithError.classList.add('devis-error');
      hasError = true;
      if (!firstInvalidInput) firstInvalidInput = inputWithError;
    }

    if (!phone) {
      inputWithError = document.getElementById('phone');
      inputWithError.classList.add('devis-error');
      hasError = true;
      if (!firstInvalidInput) firstInvalidInput = inputWithError;
    } else if (!isPhoneValid(phone)) {
      inputWithError = document.getElementById('phone');
      inputWithError?.classList.add('devis-error');
      hasError = true;
      if (!firstInvalidInput) inputWithError?.focus();
    }

    if (!email) {
      inputWithError = document.getElementById('email');
      inputWithError.classList.add('devis-error');
      hasError = true;
      if (!firstInvalidInput) firstInvalidInput = inputWithError;
    } else {
      re =
        /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

      if (!re.test(email)) {
        inputWithError = document.getElementById('email');
        inputWithError?.classList.add('devis-error');
        inputWithError?.setCustomValidity(`Entrez une adresse email valide`);
        hasError = true;
        if (!firstInvalidInput) firstInvalidInput = inputWithError;
      }

      const emailExists = await checkEmail(email);

      if (emailExists) {
        inputWithError = document.getElementById('email');
        inputWithError?.classList.add('devis-error');
        inputWithError?.setCustomValidity(
          `Adresse email appartenant déjà à un compte`
        );
        hasError = true;
        if (!firstInvalidInput) firstInvalidInput = inputWithError;
      }
    }

    if (!chosenDays.length) {
      days.map((day) => {
        inputWithError = document.getElementById(day.id);
        inputWithError.classList.add('devis-checkbox-error');
        inputWithError?.setCustomValidity(
          `Veuillez choisir un jour de rendez-vous préféré`
        );
        if (!firstInvalidInput) firstInvalidInput = inputWithError;

        return day;
      });
      hasError = true;
    }

    if (!processingConsent) {
      inputWithError = document.getElementById('processingConsent');
      inputWithError?.classList.add('devis-checkbox-error');
      inputWithError?.setCustomValidity(
        `Votre consentement est nécessaire pour traiter votre demande`
      );
      hasError = true;
      if (!firstInvalidInput) firstInvalidInput = inputWithError;
    }

    const result = {
      error: hasError,
      data: {
        processingConsent,
        marketingConsent,
        chosenDays,
        companyName,
        lastName,
        firstName,
        phone,
        email,
        cityInfo,
        clientId,
      },
    };

    if (hasError) {
      firstInvalidInput?.focus();
      firstInvalidInput?.reportValidity();
      setIsSubmitting(false);
    }

    return result;
  };

  const fetchDays = async () => {
    try {
      const response = await axios.get(`${API_PATH}days`);
      const daysData = response.data;

      setDays(daysData);
    } catch (error) {
      enqueueToast(ToastMessages.ERROR_OCCURED_FETCH, {
        theme: ToastThemes.toastThemes.ERROR_OCCURED,
        header: ToastHeaders[ToastThemes.toastThemes.ERROR_OCCURED],
        duration: 3000,
      });
      console.error('Erreur lors de la récupération des données', error);
    }
  };

  const fetchLocations = async () => {
    try {
      const response = await axios.get(`${API_PATH}locations`);
      const locationsData = response.data;

      const citiesData = locationsData.map(
        (item) => `${item.city} (${item.zipCode})`
      );

      setLocations(locationsData);
      setFormattedLocations(citiesData);
    } catch (error) {
      enqueueToast(ToastMessages.ERROR_OCCURED_FETCH, {
        theme: ToastThemes.toastThemes.ERROR_OCCURED,
        header: ToastHeaders[ToastThemes.toastThemes.ERROR_OCCURED],
        duration: 3000,
      });
      console.error('Erreur lors de la récupération des données', error);
    }
  };

  const RequestClientCityCreation = () => {
    const { cityInfo } = props.model;

    return axios.post(`${API_PATH}locations`, {
      headers: {
        'content-type': 'application/json',
      },

      country: cityInfo.country_code === 'FR' ? 'France' : 'Luxembourg',
      city: cityInfo.place_name,
      department: cityInfo.admin_name2,
      zipCode: cityInfo.postal_code,
    });
  };

  const RequestClientCreation = () => {
    const {
      chosenLegalForm,
      chosenSector,
      chosenTransferOption,
      vat,
      consultationType,
      accountingEntry,
      nbYearsBusiness,
      nbConsultations,
      serviceLevel,
      revenue,
      nbInvoices,
      turnover,
      nbEmployees,
      isCreation,
    } = props.model;

    if (city?.isCreation) {
      RequestClientCityCreation()
        .then((response) => {
          const createdLocation = response.data;
          axios({
            method: 'post',
            url: `${API_PATH}clients`,
            headers: {
              'content-type': 'application/json',
            },
            data: {
              email,
              phone,
              companyName,
              legalFormId: chosenLegalForm,
              nbYearsBusiness,
              vat,
              sectorId: chosenSector,
              nbEmployees,
              turnover,
              revenue,
              nbInvoices,
              serviceLevel,
              accountingEntry,
              processingConsent,
              marketingConsent,
              consultationType,
              nbConsultations,
              locationId: createdLocation.id,
              transferOptionId: chosenTransferOption,
              lastName,
              firstName,
              isCreation,
            },
          })
            .then((response) => {
              const clientIdData = response.data?.id;
              setClientId(clientIdData);
            })
            .catch((error) => {
              console.error(error);
              enqueueToast(ToastMessages.ERROR_OCCURED_SEND, {
                theme: ToastThemes.toastThemes.ERROR_OCCURED,
                header: ToastHeaders[ToastThemes.toastThemes.ERROR_OCCURED],
                duration: 3000,
              });
            });
        })
        .catch((error) => {
          console.error(error);
          enqueueToast(ToastMessages.ERROR_OCCURED_SEND, {
            theme: ToastThemes.toastThemes.ERROR_OCCURED,
            header: ToastHeaders[ToastThemes.toastThemes.ERROR_OCCURED],
            duration: 3000,
          });
        });

      return;
    }
    axios({
      method: 'post',
      url: `${API_PATH}clients`,
      headers: {
        'content-type': 'application/json',
      },
      data: {
        email,
        phone,
        companyName,
        legalFormId: chosenLegalForm,
        nbYearsBusiness,
        vat,
        sectorId: chosenSector,
        nbEmployees,
        turnover,
        revenue,
        nbInvoices,
        serviceLevel,
        accountingEntry,
        processingConsent,
        marketingConsent,
        consultationType,
        nbConsultations,
        locationId: city,
        transferOptionId: chosenTransferOption,
        lastName,
        firstName,
        isCreation,
      },
    })
      .then((response) => {
        const clientIdData = response.data?.id;
        setClientId(clientIdData);
      })
      .catch((error) => {
        console.error(error);
        enqueueToast(ToastMessages.ERROR_OCCURED_SEND, {
          theme: ToastThemes.toastThemes.ERROR_OCCURED,
          header: ToastHeaders[ToastThemes.toastThemes.ERROR_OCCURED],
          duration: 3000,
        });
      });
  };

  const RequestClientNeedsCreation = () => {
    const { chosenActivitiesData } = props.model;

    return axios.post(`${API_PATH}clients/${clientId}/needs`, {
      headers: {
        'content-type': 'application/json',
      },
      activities: chosenActivitiesData,
    });
  };

  const RequestClientDaysCreation = () =>
    axios.post(`${API_PATH}clients/${clientId}/days`, {
      headers: {
        'content-type': 'application/json',
      },
      days: chosenDays,
    });

  const RequestClientSkillsCreation = () => {
    const { chosenSkills } = props.model;

    return axios.post(`${API_PATH}clients/${clientId}/skills`, {
      headers: {
        'content-type': 'application/json',
      },
      skills: chosenSkills,
    });
  };

  const RequestClientQuotesCreation = () => {
    const { expertIds } = props;

    return axios.post(`${API_PATH}clients/${clientId}/quotes/bulk`, {
      headers: {
        'content-type': 'application/json',
      },
      experts: expertIds,
    });
  };

  const RequestExternalExpertCreation = async () => {
    if (!props.model.externalExpert) {
      return Promise.resolve();
    }

    const { name, yearsCollaboration, expense } = props.model;

    const response = await axios.post(`${API_PATH}external-experts`, {
      headers: {
        'content-type': 'application/json',
      },
      name,
    });

    return axios.post(`${API_PATH}clients/external-experts`, {
      headers: {
        'content-type': 'application/json',
      },
      clientId,
      expertId: response.data.id,
      expense,
      yearsCollaboration,
    });
  };

  return (
    <form>
      <div className="devis-name-surname-container">
        <div>
          <label htmlFor="companyName" className="devis-form-label">
            Nom de votre entreprise
          </label>
          <input
            className="devis-form-input-no-arrow"
            id="companyName"
            value={companyName}
            onChange={handleValueChanged}
            placeholder="Ex: Bricoflex"
          />
        </div>
        <SearchComponent
          allData={formattedLocations}
          label="Ville de résidence ou siège social"
          placeholder="Ex: Metz ou Paris"
          setSelectedItem={handleChangeLocation}
          enableCreation={true}
        />
      </div>

      <div className="devis-name-surname-container">
        <div>
          <label htmlFor="lastName" className="devis-form-label">
            Nom
          </label>
          <input
            className="devis-form-input-no-arrow"
            id="lastName"
            autoComplete="family-name"
            value={lastName}
            onChange={handleValueChanged}
            placeholder="Ex: Dupont"
          />
        </div>
        <div>
          <label htmlFor="firstName" className="devis-form-label">
            Prénom
          </label>
          <input
            className="devis-form-input-no-arrow"
            id="firstName"
            value={firstName}
            autoComplete="given-name"
            onChange={handleValueChanged}
            placeholder="Ex: Martin"
          />
        </div>
      </div>

      <span className="devis-form-label">Numéro de téléphone</span>
      <div id="phone">
        <PhoneInput
          defaultCountry="fr"
          className="devis-form-input-no-arrow"
          value={phone}
          autoComplete="phone"
          onChange={(phone) => {
            setPhone(phone);
            const input = document.getElementById('phone');
            input.classList.remove('devis-error');
          }}
          placeholder="Ex: +33612345678"
        />
      </div>

      <label htmlFor="email" className="devis-form-label">
        Adresse email
      </label>
      <input
        className="devis-form-input-no-arrow"
        id="email"
        autoComplete="email"
        value={email}
        onChange={handleValueChanged}
        placeholder="Ex: martin.dupont@gmail.com"
      />

      <div>
        <span className="devis-form-span">Vos préférences de rendez-vous</span>

        <div className="devis-wrap-container">
          {days
            ?.sort(
              (dayA, dayB) =>
                weekDays.indexOf(dayA.name) - weekDays.indexOf(dayB.name)
            )
            .map((day) => (
              <div key={day.id} className="devis-width-33">
                <input
                  type="checkbox"
                  id={day.id}
                  name={day.name}
                  className="devis-checkbox"
                  onChange={handleRdvChanged}
                />
                <label htmlFor={day.id} className="devis-form-label-box">
                  {day.name}
                </label>
              </div>
            ))}
        </div>
      </div>
      <div className="devis-consent-wrapper">
        <div className="devis-consent-container">
          <input
            type="checkbox"
            id="processingConsent"
            className="devis-checkbox"
            name="processingConsent"
            onChange={handleConsentChanged}
            checked={processingConsent === true}
            required
          />
          <label className="devis-form-label-box" htmlFor="processingConsent">
            J&apos;accepte que mes données soient utilisées pour traiter ma
            demande conformément à la{' '}
            <a
              className="devis-a-link"
              href="/cgu"
              target="_blank"
              rel="noreferrer"
            >
              politique de confidentialité
            </a>
            {/* */}.
          </label>
        </div>
      </div>

      <div className="devis-consent-container">
        <input
          type="checkbox"
          id="marketingConsent"
          className="devis-checkbox"
          name="marketingConsent"
          onChange={handleConsentChanged}
          checked={marketingConsent === true}
        />
        <label className="devis-form-label-box" htmlFor="marketingConsent">
          J&apos;aimerais recevoir des communications marketing et des mises à
          jour.
        </label>
      </div>
      <div className="devis-btn-steps-all">
        <button
          className="devis-btn-steps"
          type="button"
          id="clientCreation"
          disabled={isSubmitting}
          onClick={() => props.callRequestClientCreation()}
        >
          Générer mes 3 devis gratuits
        </button>
        <button
          className="devis-backward-steps"
          type="button"
          id="retour"
          onClick={() => props.ReturnStepClick()}
        >
          Retour
        </button>
      </div>
    </form>
  );
});

Step7.displayName = 'Step7';

Step7.propTypes = {
  updateCliendId: PropTypes.func.isRequired,
  model: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  expertIds: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
  ReturnStepClick: PropTypes.func.isRequired,
  callRequestClientCreation: PropTypes.func.isRequired,
};

export default Step7;
