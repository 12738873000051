import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';
import '../../../css/steps/step2.css';
import '../../../css/devis/devis.css';
import axios from 'axios';
import {
  ToastHeaders,
  ToastMessages,
  ToastThemes,
} from '../../Toasts/ToastOptions/toastOptions.js';
import useToast from '../../Toasts/useToast.js';
import PropTypes from 'prop-types';

const API_PATH =
  process.env.REACT_APP_API_URL ||
  'https://api.choisir-son-expert-comptable.fr/api/';

const Step2 = forwardRef((props, ref) => {
  const [activities, setActivities] = useState([]);
  const [chosenActivities, setChosenActivities] = useState(new Set());
  const [openCategory, setOpenCategory] = useState('Main');

  const enqueueToast = useToast();

  useEffect(() => {
    if (props?.currentStep !== 2) return;

    const form = document.getElementById('form-step-2');

    if (form === null) return;

    form.style.maxHeight = `${form.scrollHeight + 45}px`;
  }, [openCategory]);

  useEffect(() => {
    axios
      .get(`${API_PATH}activities`)
      .then((response) => {
        setActivities(response.data);

        // Filtrer les activités pour ne conserver que celles qui s'appellent 'Discount' et 'Markup'
        const selectedActivities = response.data.filter(
          (activity) =>
            activity.name === 'Discount' || activity.name === 'Markup'
        );

        // Extraire uniquement les identifiants des activités sélectionnées
        const selectedActivityIds = selectedActivities.map(
          (activity) => activity.id
        );

        // Mettre à jour l'état des activités choisies avec un Set
        setChosenActivities(new Set(selectedActivityIds));
      })
      .catch((error) => {
        enqueueToast(ToastMessages.ERROR_OCCURED_FETCH, {
          theme: ToastThemes.toastThemes.ERROR_OCCURED,
          header: ToastHeaders[ToastThemes.toastThemes.ERROR_OCCURED],
          duration: 3000,
        });
        console.error('Erreur lors de la récupération des données', error);
      });
  }, []);

  useEffect(() => {
    if (!props?.model?.isCreation) return;

    const defaultsActivities = new Set(chosenActivities);

    props?.model?.chosenActivitiesData.forEach((activityId) =>
      defaultsActivities.add(activityId)
    );

    setChosenActivities(defaultsActivities);
  }, [props?.model?.chosenActivitiesData]);

  const toggleCategory = (category) => {
    setOpenCategory(openCategory === category ? null : category);
  };

  const countSelectedItems = (category) =>
    activities.filter(
      (activity) =>
        activity.category === category && chosenActivities.has(activity.id)
    ).length;

  const handleChangeNeeds = (event) => {
    const { id } = event.target;
    // Créez une nouvelle instance de Set à partir de l'état actuel pour garantir que c'est un Set.
    const activityData = new Set([...chosenActivities]);

    if (activityData.has(id)) {
      activityData.delete(id);
    } else {
      activityData.add(id);
    }

    Array.from(document.getElementsByClassName('devis-checkbox')).forEach(
      (element) => element.classList.remove('devis-checkbox-error')
    );

    // Mettez à jour l'état avec le nouveau Set.
    setChosenActivities(activityData);
  };

  useImperativeHandle(ref, () => ({
    validateStep,
  }));

  const validateStep = () => {
    let hasError = false;
    let inputWithError = null;

    const chosenActivitiesData = Array.from(chosenActivities);

    if (chosenActivitiesData.length <= 2) {
      Array.from(document.getElementsByClassName('devis-checkbox')).forEach(
        (element) => {
          inputWithError = element;
          inputWithError.classList.add('devis-checkbox-error');
        }
      );
      hasError = true;
    }

    const result = {
      error: hasError,
      data: {
        chosenActivitiesData,
      },
    };

    if (hasError) {
      inputWithError?.focus();
      inputWithError?.reportValidity();
    }

    return result;
  };

  const mainActivities = activities.filter(
    (activity) => activity.category === 'Main'
  );
  const otherActivities = activities.filter(
    (activity) =>
      !(
        activity.category === 'Main' ||
        activity.category === 'Startup' ||
        activity.category === 'Hidden'
      )
  );

  function getOtherActivities() {
    // Tri et réduction des activités en catégories
    const categorizedActivities = otherActivities
      ?.sort((activityA, activityB) =>
        activityA.description.localeCompare(activityB.description)
      )
      .reduce((acc, activity) => {
        const category = activity.category || 'Autre';
        acc[category] = acc[category] || [];
        acc[category].push(activity);

        return acc;
      }, {});

    // Génération du JSX pour chaque catégorie
    const otherActivitiesJSX = Object.entries(categorizedActivities).map(
      ([category, activities]) => (
        <div key={category} className="devis-form-category">
          <button
            onClick={() => toggleCategory(category)}
            className={`devis-form-category-button ${
              openCategory === category ? 'open' : ''
            }`}
            style={{ cursor: 'pointer' }}
          >
            {category}
            {countSelectedItems(category) > 0 && (
              <span
                className={`category-counter ${
                  countSelectedItems(category) > 0 ? 'counter-active' : ''
                }`}
              >
                {countSelectedItems(category)}
              </span>
            )}
          </button>
          <div
            className={`devis-form-activities ${
              openCategory === category ? 'visible' : 'hidden'
            }`}
          >
            {activities.map((activity) => (
              <div className="devis-form-column-bi" key={activity.id}>
                <input
                  type="checkbox"
                  id={activity.id}
                  name={activity.name}
                  className="devis-checkbox"
                  checked={chosenActivities.has(activity.id)}
                  onChange={(e) => handleChangeNeeds(e)}
                />
                <label htmlFor={activity.id} className="devis-form-label-box">
                  {activity.description}
                </label>
              </div>
            ))}
          </div>
        </div>
      )
    );

    return otherActivitiesJSX;
  }

  return (
    <div className="row">
      <span className="devis-form-span">
        Cochez vos besoins
        <br />
      </span>
      <div key="Main" className="devis-form-category">
        {mainActivities.length !== 0 && (
          <button
            onClick={() => toggleCategory('Main')}
            className={`devis-form-category-button ${
              openCategory === 'Main' ? 'open' : ''
            }`}
            style={{ cursor: 'pointer' }}
          >
            {'Comptabilité Générale'}
            {countSelectedItems('Main') > 0 && (
              <span
                className={`category-counter ${
                  countSelectedItems('Main') > 0 ? 'counter-active' : ''
                }`}
              >
                {countSelectedItems('Main')}
              </span>
            )}
          </button>
        )}
        <div
          className={`devis-form-activities ${
            openCategory === 'Main' ? 'visible' : 'hidden'
          }`}
        >
          {mainActivities
            ?.sort((activityTaskA, activityTaskB) =>
              activityTaskA.description.localeCompare(activityTaskB.description)
            )
            .map((activity) => (
              <div className="devis-form-column-bi" key={activity.id}>
                <input
                  key={activity.id}
                  type="checkbox"
                  id={activity.id}
                  name={activity.name}
                  className="devis-checkbox"
                  onChange={handleChangeNeeds}
                  checked={chosenActivities.has(activity.id)}
                />
                <label htmlFor={activity.id} className="devis-form-label-box">
                  {activity.description}
                </label>
              </div>
            ))}
        </div>
      </div>
      {getOtherActivities()}
    </div>
  );
});

Step2.propTypes = {
  model: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  currentStep: PropTypes.number,
};

Step2.displayName = 'Step2';

export default Step2;
