import React, { forwardRef, useState } from 'react';
import { Link } from 'react-router-dom';
import '../../css/contact.css';
import background1 from '../../css/images/background-1.jpg';
import useToast from '../Toasts/useToast.js';
import ToastsOptions, {
  ToastHeaders,
  ToastThemes,
  ToastMessages,
} from '../Toasts/ToastOptions/toastOptions.js';
import axios from 'axios';

const API_PATH =
  process.env.REACT_APP_API_URL ||
  'https://api.choisir-son-expert-comptable.fr/api/';

const Contact = forwardRef(() => {
  const [contactData, setContactData] = useState({
    lastName: '',
    firstName: '',
    subject: '',
    email: '',
    message: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const enqueueToast = useToast();

  const handleValueChange = (e) => {
    const { value } = e.target;
    const { id } = e.target;

    setContactData((prevState) => ({ ...prevState, [id]: value }));

    document.getElementById(id).classList.remove('contact-error');
  };

  const sendEmail = async () => {
    const { email, message, lastName, firstName, subject } = contactData;

    axios
      .post(`${API_PATH}emails/contact`, {
        message,
        email,
        lastName,
        firstName,
        subject,
      })
      .then(() => {
        enqueueToast(ToastMessages.SEND_CONTACT, {
          theme: ToastThemes.toastThemes.SEND_CONTACT,
          header: ToastHeaders[ToastThemes.toastThemes.SEND_CONTACT],
          duration: 3000,
          position: ToastsOptions.ToastPositions.BOTTOM_CENTER,
        });
        setContactData({
          lastName: '',
          firstName: '',
          subject: '',
          email: '',
          message: '',
        });

        setIsSubmitting(false);
      })
      .catch((error) => {
        enqueueToast(ToastMessages.ERROR_OCCURED_CONTACT, {
          theme: ToastThemes.toastThemes.ERROR_OCCURED,
          header: ToastHeaders[ToastThemes.toastThemes.ERROR_OCCURED],
          duration: 3000,
        });
        console.error(error);
        setIsSubmitting(false);
      });
  };

  const validationMessage = () => {
    let error = false;
    setIsSubmitting(true);

    for (const [key, value] of Object.entries(contactData)) {
      if (value === '') {
        document.getElementById(key).classList.add('contact-error');
        error = true;
      }
    }

    if (error) {
      setIsSubmitting(false);

      return;
    }

    sendEmail();
  };

  return (
    <div className="contact-container">
      <div className="contact-reviews-and-form">
        <div className="background-img">
          <img className="banner" src={background1} alt="" />
        </div>
        <div className="contact-container-infos">
          <h2 className="contact-title">
            <span className="title-bluesoft">Vous êtes expert-comptable ?</span>
            <br />
            Devenez partenaire : boostez votre activité d'expert-comptable
            <br /> avec des leads qualifié !
          </h2>
          <div className="text-contact">
            <p>
              CEC, c'est un accélérateur de croissance pour votre cabinet
              d'expertise comptable.
            </p>
            <div>
              <p>
                CEC, l'accélérateur de croissance pour votre cabinet d'expertise
                comptable, offre une plateforme innovante regroupant plusieurs
                fonctionnalités. Tout d'abord, il s'agit d'un générateur de
                leads qualifiés, assurant un flux continu de nouveaux clients en
                accord avec vos stratégies et besoins. Ensuite, CEC agit en tant
                qu'apporteur d'affaires, se chargeant de la prospection et
                facilitant la mise en relation avec des clients potentiels
                ciblés. De plus, il fonctionne comme un générateur de clients,
                permettant une conversion rapide des prospects en clients
                payants.
              </p>
            </div>
            <div className="paracontact">
              <span className="text-blue">
                <strong>Fonctionnement simple et transparent :</strong>
              </span>
              <p className="point">
                • Référencez votre cabinet sur choisir-son-expert-comptable.fr
              </p>
              <p className="point">
                • Définissez vos besoins et votre stratégie de prospection
              </p>
              <p className="point">
                • CEC s'occupe du reste : génération de leads, prospection,
                qualification
              </p>
              <p className="point">
                • Vous n'avez plus qu'à accepter de nouveaux clients !
              </p>
            </div>
            <div className="paracontact">
              <span className="text-blue">
                <strong>Notre rémunération est simple et transparente :</strong>
              </span>
              <p className="point">
                • 8% HT de commission sur les honoraires de la lettre de mission
                de la première année en cas de contractation avec un client
                apporté
              </p>
              <p className="point ">
                <span className="text-blue">
                  <strong>• Soit moins d'un mois de lettre de mission</strong>
                </span>
              </p>
            </div>
            <div>
              <p>
                En résumé, CEC vous permet de gagner du temps et de vous
                concentrer sur votre cœur de métier, de développer votre
                activité rapidement et efficacement, de cibler votre clientèle
                en fonction de votre stratégie cabinet.
              </p>
            </div>
            <div>
              <p>
                <span className="text-blue">
                  <strong>Contactez-nous pour vous référencer.</strong>
                </span>
              </p>
            </div>
          </div>
          <div className="contact-container-global">
            <div className="contact-contact-form">
              <div className="contact-infos">
                <Link to="#" className="contact-link">
                  Nous contacter directement
                </Link>
                <h3>Choisir son expert comptable</h3>
                <div className="contact-infos-row">
                  <p>contact@choisir-son-expert-comptable.fr </p>
                </div>
                <div className="contact-infos-row">
                  <p>+33 7 66 20 77 52</p>
                </div>
              </div>
              <div className="contact-container-form">
                <div className="contact-form-row">
                  <div className="contact-form-item">
                    <input
                      type="text"
                      id="lastName"
                      autoComplete="family-name"
                      value={contactData.lastName}
                      onChange={handleValueChange}
                    />
                    <label htmlFor="lastName">Nom</label>
                  </div>
                  <div className="contact-form-item">
                    <input
                      type="text"
                      id="firstName"
                      autoComplete="given-name"
                      value={contactData.firstName}
                      onChange={handleValueChange}
                    />
                    <label htmlFor="firstName">Prenom</label>
                  </div>
                </div>
                <div className="contact-form-row">
                  <div className="contact-form-item">
                    <input
                      type="text"
                      id="subject"
                      value={contactData.subject}
                      onChange={handleValueChange}
                    />
                    <label htmlFor="subject">Sujet de votre message</label>
                  </div>
                  <div className="contact-form-item">
                    <input
                      type="email"
                      id="email"
                      autoComplete="email"
                      value={contactData.email}
                      onChange={handleValueChange}
                    />
                    <label htmlFor="email">Email</label>
                  </div>
                </div>
                <div className="contact-form-textarea">
                  <textarea
                    id="message"
                    value={contactData.message}
                    onChange={handleValueChange}
                  ></textarea>
                  <label htmlFor="message">Votre message</label>
                </div>
                <button
                  type="button"
                  className="contact-btn"
                  onClick={validationMessage}
                  disabled={isSubmitting}
                >
                  Envoyer message
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

Contact.displayName = 'Contact';

export default Contact;
