import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const FRONT_CLIENT_URL =
  process.env.REACT_APP_FRONT_CLIENT_URL ||
  'https://espace.choisir-son-expert-comptable.fr/login';

function QuotesModal(props) {
  return (
    <div className="container-modal">
      <button
        className="background-modal"
        onClick={(e) => props.closeModal(e)}
      ></button>
      <div className="modal">
        <div className="modal-expert-title">
          Voulez-vous consulter ce devis ?
        </div>
        <div className="modal-expert-detail">
          En confirmant votre choix, vous serez redirigé vers la page de
          connexion de notre espace client. Vous pourrez ensuite être mis en
          contact avec l'expert-comptable choisi.
        </div>
        <Link to={FRONT_CLIENT_URL} className="choose-expert">
          Consulter ce devis
        </Link>
        <button
          className="forward-modal"
          onClick={() => props.setShowModal(false)}
        >
          Annuler
        </button>
      </div>
    </div>
  );
}

QuotesModal.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default QuotesModal;
