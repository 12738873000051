import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';
import '../../../css/steps/step2.css';
import '../../../css/devis/devis.css';
import axios from 'axios';
import useToast from '../../Toasts/useToast.js';
import {
  ToastMessages,
  ToastHeaders,
  ToastThemes,
} from '../../Toasts/ToastOptions/toastOptions.js';
import PropTypes from 'prop-types';

const API_PATH =
  process.env.REACT_APP_API_URL ||
  'https://api.choisir-son-expert-comptable.fr/api/';

const Step3 = forwardRef((props, ref) => {
  const [skills, setSkills] = useState([]);
  const [chosenSkills, setChosenSkills] = useState(
    props.model?.chosenSkills || []
  );
  const [openCategory, setOpenCategory] = useState('Langues');

  useEffect(() => {
    if (props?.currentStep !== 3) return;

    const elm = document.getElementById('form-step-3');

    if (elm === null) return;

    elm.style.maxHeight = `${elm.scrollHeight + 45}px`;
  }, [openCategory]);

  const enqueueToast = useToast();

  useEffect(() => {
    axios
      .get(`${API_PATH}skills`)
      .then((response) => {
        const skillsData = response.data;
        const sortedSkills = sortSkillsByCategory(skillsData);
        setSkills(sortedSkills);
        const FrenchSkill = skillsData?.find(
          (skill) => skill.name === 'Français'
        );
        setChosenSkills(FrenchSkill ? [FrenchSkill.id] : []);
      })
      .catch((error) => {
        enqueueToast(ToastMessages.ERROR_OCCURED_FETCH, {
          theme: ToastThemes.toastThemes.ERROR_OCCURED,
          header: ToastHeaders[ToastThemes.toastThemes.ERROR_OCCURED],
          duration: 3000,
        });
        console.error('Erreur lors de la récupération des données', error);
      });
  }, []);

  const toggleCategory = (category) => {
    setOpenCategory(openCategory === category ? null : category);
  };

  const handleChangeSkills = (event) => {
    const { id } = event.target;

    const skillData = new Set(chosenSkills);

    if (skillData.has(id)) {
      // If the skill is already chosen, remove it
      skillData.delete(id);
    } else {
      // If the skill is not currently chosen, add it
      skillData.add(id);
    }

    Array.from(document.getElementsByClassName('devis-checkbox')).forEach(
      (element) => element.classList.remove('devis-checkbox-error')
    );

    setChosenSkills(Array.from(skillData));
  };

  const sortSkillsByCategory = (skills) =>
    skills.reduce((acc, skill) => {
      const { category } = skill;

      // Si la catégorie n'est pas encore dans l'accumulateur, ajoutez-la
      if (!acc[category]) {
        acc[category] = [];
      }

      // Ajoutez la compétence à la catégorie appropriée
      acc[category].push(skill);

      return acc;
    }, {});

  useImperativeHandle(ref, () => ({
    validateStep,
  }));

  const validateStep = () => {
    let hasError = false;
    let inputWithError = null;

    if (!chosenSkills.length) {
      Array.from(document.getElementsByClassName('devis-checkbox')).forEach(
        (element) => {
          inputWithError = element;
          inputWithError.classList.add('devis-checkbox-error');
        }
      );
      hasError = true;
    }

    const result = {
      error: hasError,
      data: {
        chosenSkills,
      },
    };

    if (hasError) {
      inputWithError?.focus();
      inputWithError?.reportValidity();
    }

    return result;
  };

  const countSelectedItems = (category) => {
    // Accéder à la liste des compétences pour la catégorie donnée, ou utiliser un tableau vide si elle n'existe pas
    const categorySkills = skills[category] || [];

    // Compter les éléments sélectionnés dans cette catégorie
    return categorySkills.filter((skill) => chosenSkills.includes(skill.id))
      .length;
  };

  return (
    <div className="row">
      <span className="devis-form-span">Cochez vos besoins</span>
      <div id="skills">
        {Object.keys(skills)
          ?.sort((categoryA, categoryB) => categoryA.localeCompare(categoryB))
          .map(
            (category) =>
              !props.model?.isCreation && (
                <div className="devis-form-category" key={category}>
                  <button
                    className={`devis-form-category-button ${
                      openCategory === category ? 'open' : ''
                    }`}
                    onClick={() => toggleCategory(category)}
                    style={{ cursor: 'pointer' }}
                  >
                    {(() => {
                      if (category === 'Langues') {
                        return `${category} (Obligatoire)`;
                      }

                      return `${category} (Optionnel) `;
                    })()}
                    {countSelectedItems(category) > 0 && (
                      <span
                        className={`category-counter ${
                          countSelectedItems(category) > 0
                            ? 'counter-active'
                            : ''
                        }`}
                      >
                        {countSelectedItems(category)}
                      </span>
                    )}
                  </button>
                  <div
                    className="devis-form-activities"
                    style={{ display: 'flex' }}
                  >
                    {skills[category]
                      ?.sort((skillA, skillB) =>
                        skillA.name.localeCompare(skillB.name)
                      )
                      ?.map(
                        (skill) =>
                          openCategory === category && (
                            <div
                              className="devis-form-column-tri"
                              key={skill.name}
                            >
                              <input
                                type="checkbox"
                                id={skill.id}
                                name={skill.name}
                                className="devis-checkbox"
                                onChange={handleChangeSkills}
                                checked={chosenSkills?.includes(skill.id)}
                              />
                              <label
                                htmlFor={skill.id}
                                className="devis-form-label-box"
                              >
                                {skill.name}
                              </label>
                            </div>
                          )
                      )}
                  </div>
                </div>
              )
          )}
        {props.model?.isCreation && (
          <div className="devis-form-category" key="Langues">
            <span
              className={`devis-form-category-text ${
                openCategory === 'Langues' ? 'open' : ''
              }`}
              onClick={() => toggleCategory('Langues')}
              style={{ cursor: 'pointer' }}
            >
              Langues (Obligatoire)
              {countSelectedItems('Langues') > 0 && (
                <span
                  className={`category-counter ${
                    countSelectedItems('Langues') > 0 ? 'counter-active' : ''
                  }`}
                >
                  {countSelectedItems('Langues')}
                </span>
              )}
            </span>
            <div className="devis-form-activities" style={{ display: 'flex' }}>
              {skills?.Langues?.sort((skillA, skillB) =>
                skillA.name.localeCompare(skillB.name)
              )?.map(
                (skill) =>
                  openCategory === 'Langues' && (
                    <div className="devis-form-column-tri" key={skill.name}>
                      <input
                        type="checkbox"
                        id={skill.id}
                        name={skill.name}
                        className="devis-checkbox"
                        onChange={handleChangeSkills}
                        checked={chosenSkills?.includes(skill.id)}
                      />
                      <label
                        htmlFor={skill.id}
                        className="devis-form-label-box"
                      >
                        {skill.name}
                      </label>
                    </div>
                  )
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

Step3.propTypes = {
  model: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  currentStep: PropTypes.number,
};

Step3.displayName = 'Step3';

export default Step3;
