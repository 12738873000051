import React from 'react';
import { Fade } from 'react-awesome-reveal';
import SEO from '../SEO/seo.js';

const Mention = () => (
  <>
    <SEO
      pageType="website"
      pageTitle="Choisir Son Expert Comptable - CEC - Mentions"
      pageDescription="Mentions légales de la plateforme CEC - Choisir son Expert-Comptable."
      pageURL="https://www.choisir-son-expert-comptable.fr/mention"
    />
    <Fade duration={2000}>
      <div className="cgu container mention">
        <h1>Mentions légales et politique de confidentialité</h1>
        <p>
          La société CEC (Choisir son expert comptable), soucieuse des droits
          des individus, notamment au regard des traitements automatisés et dans
          une volonté de transparence avec ses clients, a mis en place une
          politique reprenant l’ensemble de ces traitements, des finalités
          poursuivies par ces derniers ainsi que des moyens d’actions à la
          disposition des individus afin qu’ils puissent au mieux exercer leurs
          droits.
        </p>

        <p>
          Pour toute information complémentaire sur la protection des données
          personnelles, nous vous invitons à consulter le site :{''}
          <a href="https://www.cnil.fr/">https://www.cnil.fr/</a>
        </p>

        <p>
          La poursuite de la navigation sur ce site vaut acceptation sans
          réserve des dispositions et conditions d&apos;utilisation qui suivent.
        </p>

        <p>
          La version actuellement en ligne de ces conditions d&apos;utilisation
          est la seule opposable pendant toute la durée d&apos;utilisation du
          site et jusqu&apos;à ce qu&apos;une nouvelle version la remplace.
        </p>

        <h2>Article 1 - Mentions légales</h2>

        <p>
          1.1 Site (ci-après « le site ») : CEC Choisir son expert-comptable
        </p>

        <p>
          1.2 Éditeur (ci-après « l&apos;éditeur ») : CEC Choisir son expert
          comptable adresse mail : cec@choisir-son-expert-comptable.fr
        </p>

        <p>
          1.3 Hébergeur (ci-après « l&apos;hébergeur ») : CEC (Choisir son
          expert-comptable) est hébergé par OVH, dont le siège social est situé
          2 rue Kellermann 59100 Roubaix.
        </p>

        <h2>Article 2 - Accès au site</h2>

        <p>
          L&apos;accès au site et son utilisation sont réservés à un usage
          strictement personnel. Vous vous engagez à ne pas utiliser ce site et
          les informations ou données qui y figurent à des fins commerciales,
          politiques, publicitaires et pour toute forme de sollicitation
          commerciale et notamment l&apos;envoi de courriers électroniques non
          sollicités.
        </p>

        <h2>Article 3 - Contenu du site</h2>

        <p>
          Toutes les marques, photographies, textes, commentaires,
          illustrations, images animées ou non, séquences vidéo, sons, ainsi que
          toutes les applications informatiques qui pourraient être utilisées
          pour faire fonctionner ce site et plus généralement tous les éléments
          reproduits ou utilisés sur le site sont protégés par les lois en
          vigueur au titre de la propriété intellectuelle.
        </p>

        <p>
          Ils sont la propriété pleine et entière de l&apos;éditeur ou de ses
          partenaires. Toute reproduction, représentation, utilisation ou
          adaptation, sous quelque forme que ce soit, de tout ou partie de ces
          éléments, y compris les applications informatiques, sans l&apos;accord
          préalable et écrit de l&apos;éditeur, sont strictement interdites. Le
          fait pour l&apos;éditeur de ne pas engager de procédure dès la prise
          de connaissance de ces utilisations non autorisées ne vaut pas
          acceptation desdites utilisations et renonciation aux poursuites.
        </p>
      </div>
    </Fade>
  </>
);

export default Mention;
